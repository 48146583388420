import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  homeState: "Restart",
};

export const HomeSlice = createSlice({
  name: "home",
  initialState,
  reducers: {
    setHomeState(state, action) {
      state.homeState = action.payload;
    },
  },
});

export const { setHomeState } = HomeSlice.actions;

export default HomeSlice.reducer;
