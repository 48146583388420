import React from "react";
import ConsultationForm from "../components/ConsultationForm";
import Summary from "../components/Summary";
import { coachingData, courses } from "../utils/constants";
import youthImage from "../assets/images/WhatsApp Image 2024-11-07 at 15.32.23_de273ebf.webp";
import Team from "../components/shared/Team";

const ForYouth =React.memo( () => {
  return (
    <div className="container flex flex-col gap-14 mt-8">
      <Summary
        title={
          <>
            Restoring <span className="text-highLight_color">Hope</span>: A New
            Beginning Beyond Addiction
          </>
        }
        Descriptions="Our team at Restart is a group of dedicated professionals, including doctors, therapists, and trainers, committed to supporting individuals through their recovery. We offer personalized care that addresses physical, emotional, and psychological needs, helping clients achieve lasting wellness and regain control of their lives."
        imageSrc={youthImage}
        hideBtn
      />
      <Team
        title="Psychiatrists"
        data={coachingData.filter((item) => item.category === "psychiatrist")}
      />
      <Team
        title="Psychotherapists"
        data={coachingData.filter((item) => item.category === "psychologist")}
      />
      <Team
        title="Recovery coaching"
        data={coachingData.filter((item) => item.category === "recovery")}
      />
      <Team
        title="Fitness and Nutrition Trainers"
        data={coachingData.filter((item) => item.category === "fitness")}
      />
      <Team
        title="Family Coach"
        data={coachingData.filter((item) => item.category === "familyCoach")}
      />
      <Team
        title="Logistics and Management"
        data={coachingData.filter((item) => item.category === "management")}
      />
      <Team title="Books , Courses" data={courses} />

      {/* <Summary
        title={
          <>
            Intensive programme{" "}
            <span className="text-highLight_color">10 days</span>
          </>
        }
        Descriptions={
          <span>
            This 10-day program offers a comprehensive, personalized approach to
            understanding and overcoming addiction. The program includes
            sessions with doctors, experts, and specialists to create a
            detailed, tailored recovery plan that fits your specific needs.
          </span>
        }
        imageSrc={daysImage}
        btnTitle="Get Now"
      /> */}

      <ConsultationForm />
    </div>
  );
});

export default ForYouth;
