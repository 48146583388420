import React from "react";

const Phase = React.memo(({ phase }) => {
  return (
    <div className="flex flex-col gap-8 w-full md:w-1/2">
      <div className="flex flex-col gap-4">
        <small>Phase {phase.id}</small>
        <b className="text-xl">{phase.title}</b>
      </div>
      <ul className="px-2">
        {phase?.description.map((text, index) => (
          <div key={index} className="flex items-start gap-2"><span className="w-1 h-1 mt-2 rounded-full bg-black flex-none"></span><li>{text}</li></div>
        ))}
      </ul>
      {phase.duration ? (
        <div className="flex items-center">
          <span>Duration:</span>
          <b className="text-highLight_color">{phase.duration}</b>
        </div>
      ) : (
        <b className="text-highLight_color">Sustainably</b>
      )}
    </div>
  );
});

export default Phase;
