import React from "react";
import HeroPage from "../components/HeroPage";
import Summary from "../components/Summary";
import TestimonialsItem from "../components/eliteWellbeing/TestimonialsItem";
import CommentsSection from "../components/shared/CommentsSection";
import summaryImage from "../assets/images/WhatsApp Image 2024-11-07 at 14.51.24_215acdb7.webp";
import { useTranslation } from "react-i18next";
// import { motion } from "framer-motion";
import {
  courses,
  coachingData,
  programsFunc,
  commentsEliteWellbeingData,
} from "../utils/constants";
import heroImage from "../assets/images/WhatsApp_Image_2024-11-27_at_23.58.30_c22ba8e9-removebg-preview.webp";
import Team from "../components/shared/Team";
import Programs from "../components/Programs";

// const { Meta } = Card;

// const animationRightToLeft = {
//   hidden: { opacity: 0, x: 50 },
//   visible: (index) => ({
//     opacity: 1,
//     x: 0,
//     transition: {
//       delay: index * 0.2,
//       duration: 0.5,
//     },
//   }),
// };

const Home = React.memo(() => {
  const { t } = useTranslation();

  const programs = programsFunc(t)

  return (
    <div className="flex flex-col gap-14">
      <HeroPage
        title={t("heroImage.title")}
        desc={t("heroImage.desc")}
        btnTitle={t("header.contact_now")}
        ratingData={[
          { value: "+3000", name: t("titles.well_being") },
          { value: "78%", name: t("titles.success_rate") },
          { value: "12M", name: t("titles.scope_of_impact") },
        ]}
        heroImage={heroImage}
        btnPath="https://wa.me/+905551675716"
      />
      <div className="container mx-auto flex flex-col gap-14">
        <Summary
          title={
            <div>
              The Restart Team Began With{" "}
              <span className="text-highLight_color">Saad Al-Mahmoud</span>
            </div>
          }
          Descriptions="Saad, the founder of the Restart team, combines his expertise in recovery coaching, mental health, fitness, and nutrition to help individuals overcome addiction. With a master’s degree in Clinical Mental Health Counseling and certifications in nutrition and personal training, he is dedicated to guiding others toward lasting wellness and resilience."
          isVideo
          btnTitle={t('titles.read_more')}
          btnURL="/our_story"
        />

        <Team data={coachingData} title={t('header.our_team')} path="/our-team" inHome />
        <Programs data={programs} title={t('header.our_programs')} path="/program" />

        <Summary
          title={
            <div>
              We Offer{" "}
              <span className="text-highLight_color">Elite Wellbeing</span>
              To Help You And Your Family
            </div>
          }
          btnTitle={t('titles.askHelp')}
          Descriptions="The Elite Well-being program is an innovative and comprehensive treatment for substance addiction, specifically tailored for youth in the Arab region. It combines global best practices with culturally relevant approaches, emphasizing individualized care. Each participant receives a personalized treatment plan, therapeutic activities, and support from dedicated coaches, ensuring privacy and a focus on long-term recovery. The program promises lasting sobriety with a two-year guarantee after completion, contrasting with traditional methods that often limit personal freedom."
          imageSrc={summaryImage}
          btnURL="https://wa.me/+905551675716"
          reverse
        />

        <div className="flex flex-col gap-4 sm:hidden">
          <h3 className="text-xl text-center font-bold uppercase">
            Our Recovers Testimonials
          </h3>
          <p className="text-center">
            Listen to their stories and get inspired
          </p>
          {commentsEliteWellbeingData.map((item, index) => (
            <TestimonialsItem key={index} item={item} inHome />
          ))}
        </div>
        <div className="hidden flex-col gap-4 bg-bg_lightGray sm:flex">
          <CommentsSection data={commentsEliteWellbeingData} />
        </div>

        <Team data={courses} title="Books , Courses" path="/our_story" />
      </div>
    </div>
  );
});

export default Home;
