import React from "react";
import { phases } from "../../utils/constants";
import Phase from "./Phase";

const RecoveryStages = React.memo(() => {
  return (
    <div className="flex flex-col gap-8">
      <h3 className="font-bold text-xl lg:text-3xl capitalize text-center">
        Recovery stages in Elite Wellbeing
      </h3>
      {phases.map((phase, index) => (
        <div
          className={`w-full h-full flex items-start gap-2 ${
            index % 2 === 0 ? "md:flex-row-reverse" : "md:flex-row"
          } flex-col-reverse`}
          key={index}
        >
          <Phase phase={phase} />

          <div>
            <div className="text-white rounded-lg bg-black w-8 h-8 flex items-center justify-center">
              {phase.id}
            </div>
          </div>

          <div className="rounded-lg overflow-hidden w-full md:w-1/2 max-h-80 h-80">
            <img
              src={phase.image}
              alt="section_image"
              className="w-full h-full object-cover"
              loading="lazy"
            />
          </div>
        </div>
      ))}
    </div>
  );
});

export default RecoveryStages;
