import React from "react";
import heroImage from "../assets/images/DSC00662.webp";
import HeroPage from "../components/HeroPage";
import { useTranslation } from "react-i18next";
import { courses } from "../utils/constants";
import Summary from "../components/Summary";

const AboutSaad = React.memo(() => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-14">
      <HeroPage
        title={t("heroImage.title2")}
        desc={t("heroImage.desc2")}
        heroImage={heroImage}
      />

      <div className="container flex flex-col gap-14">
        <div className="flex flex-col gap-6 justify-center">
          <h3 className="font-bold text-2xl lg:text-4xl text-center w-full">
            About Us
          </h3>

          <p className="md:w-3/4 w-full mx-auto text-lg">
            We are a dedicated team of doctors, specialists, and trainers
            committed to helping individuals struggling with addiction achieve
            lasting, real recovery through a holistic approach that addresses
            physical, psychological, and spiritual health. By providing
            comprehensive and effective treatment, we aim to guide individuals
            through their recovery journey and help them build a better life.
            Our mission is to build a strong and sustainable recovery community
            for Arabic-speaking individuals, offering innovative treatment
            programs backed by the latest research. We provide essential
            psychological, social, and professional support that empowers
            individuals to overcome addiction and thrive. Our goal is to reach
            one million recovered individuals across the Arab world and create a
            supportive environment that fosters sustainable healing.
            Additionally, we are working to train 1,000 coaches and supporters
            over the next 25 years. Each coach will be equipped to help 1,000
            individuals over a period of 10 to 15 years, creating a broad
            network of experts capable of supporting long-term recovery across
            Arab communities. We believe that recovery is not just an individual
            journey, but a collective movement that requires the collaboration
            of efforts at all levels. We are committed to providing a platform
            that integrates both Arabic cultural values and the best global
            practices in addiction recovery.
          </p>
        </div>

        <div className="flex flex-col gap-8 justify-center">
          <div className="w-full flex justify-center">
            <span className="font-bold text-2xl lg:text-4xl text-center w-full">
              Training Courses and Workshops from {" "}
              <span className="text-highLight_color">Saed Al-mahmoud</span>
            </span>

          </div>

          {courses.map((course, index) => (
            <Summary
              btnType="default"
              btnURL={`/${course.path}/${course.id}`}
              title={course.name}
              btnTitle="Get Now"
              Descriptions={course.description}
              imageSrc={course.img}
              reverse={index % 2 !== 0}
            />
          ))}
        </div>
      </div>
    </div>
  );
});

export default AboutSaad;
