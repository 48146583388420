import React from "react";
import TestimonialsItem from "./TestimonialsItem";
import { testimonials } from "../../utils/constants";

const Testimonials = React.memo(() => {
  return (
    <div className="rounded-xl flex flex-col gap-8 shadow-md testimonials_bg p-8">
      <div className="flex justify-center w-full items-center flex-col gap-2">
        <h3 className="text-3xl font-bold text-white uppercase">
          Our Recovers Testimonials
        </h3>
        <small className="text-white">
          listen to their stories and ready to change
        </small>
      </div>
      <div className="grid gap-4 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4">
        {testimonials.map((item) => (
          <TestimonialsItem key={item} item={item} />
        ))}
      </div>
    </div>
  );
});

export default Testimonials;
