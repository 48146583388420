import React, { useRef } from "react";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { breakpoints } from "../../utils/constants";
import { Button } from "antd";
import CardItem from "../../components/CardItem";
import TeamMember from "./TeamMember";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Link } from "react-router-dom";

const Team = React.memo(({ title, inHome, data, path }) => {
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  return (
    <div className="flex flex-col gap-8 cards_slider">
      <div className="flex items-center justify-between gap-2">
        <Link to={path} className="font-bold text-lg lg:text-3xl uppercase hover:underline">{title}</Link>
        <div className="flex items-center gap-4">
          <Button type="primary" ref={prevRef}>
            <ArrowLeftOutlined />
          </Button>
          <Button type="primary" ref={nextRef}>
            <ArrowRightOutlined />
          </Button>
        </div>
      </div>

      <Swiper
        slidesPerView={1}
        spaceBetween={10}
        breakpoints={breakpoints}
        navigation={{
          prevEl: prevRef.current,
          nextEl: nextRef.current,
        }}
        onInit={(swiper) => {
          swiper.params.navigation.prevEl = prevRef.current;
          swiper.params.navigation.nextEl = nextRef.current;
          swiper.navigation.init();
          swiper.navigation.update();
        }}
        modules={[Navigation]}
      >
        {data.map((item, index) => (
          <SwiperSlide key={index} className="h-full">
            {inHome ? <TeamMember item={item} /> : <CardItem item={item} />}
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
});

export default Team;
