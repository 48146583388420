import { configureStore } from "@reduxjs/toolkit";
import homeReducer from "./features/HomeSlice";
import sharedSlice from "./features/SharedSlice"

export const store = configureStore({
  reducer: {
    home: homeReducer,
    shared: sharedSlice,
  },
});
