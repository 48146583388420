import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

const initialState = {
  isArabic: Cookies.get("i18next") === "ar",
};

export const SharedSlice = createSlice({
  name: "shared",
  initialState,
  reducers: {
    setIsArabic(state, action) {
      state.isArabic = action.payload; // Expect payload to be a boolean (true/false)
      Cookies.set("i18next", action.payload ? "ar" : "en"); // Update the cookie when the language changes
    },
  },
});

export const { setIsArabic } = SharedSlice.actions;

export default SharedSlice.reducer;
