import React from "react";
import Summary from "../components/Summary";
import summaryImage from "../assets/images/WhatsApp Image 2024-11-07 at 15.05.36_509186d6.webp";
import { useTranslation } from "react-i18next";
import OurValue from "../components/eliteWellbeing/OurValue";
import { ourValues, ourOffers } from "../utils/constants";
import ConsultationForm from "../components/ConsultationForm";
import Testimonials from "../components/eliteWellbeing/Testimonials";
import RestartPartners from "../components/eliteWellbeing/RestartPartners";
import RecoveryStages from "../components/eliteWellbeing/RecoveryStages";
import EliteWellbeingVideo from "../components/eliteWellbeing/EliteWellbeingVideo";
import HeroPage from "../components/HeroPage.jsx";
import eliteWellbeing from "../assets/images/elitewellbeing.webp";

const EliteWellbeing = React.memo(() => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col gap-14">
      <HeroPage
        title={t("eliteWellbeing.offering")}
        desc={t("eliteWellbeing.offerDesc")}
        heroImage={eliteWellbeing}
      />
      <div className="container flex flex-col gap-14">
        <EliteWellbeingVideo />
        <RestartPartners />
        <Summary
          title={t("titles.who_are_we")}
          Descriptions={
            <div>
              <b>Elite Wellbeing Program</b>
              <p>
                Elite Wellbeing is a comprehensive program designed specifically
                to assist Arab youth struggling with addiction, aiming to
                achieve genuine and sustainable recovery through a holistic
                approach that addresses physical, psychological, and spiritual
                health.
              </p>

              <b>Program Phases</b>
              <p>The program unfolds in three integrated phases:</p>
              <ol>
                <li>
                  <strong>Detoxification Stage:</strong> This stage focuses on
                  safely removing addictive substances from the body.
                </li>
                <li>
                  <strong>Recovery Stage:</strong> Emphasizes restoring mental
                  and physical health through personalized therapy sessions.
                </li>
                <li>
                  <strong>Community Reintegration Stage:</strong> Enhances
                  social skills and effective communication for a smooth
                  transition back into society.
                </li>
              </ol>
            </div>
          }
          imageSrc={summaryImage}
          hideBtn
        />

        <div className="w-full mt-[-32px]">
          <p>
            Each phase is carefully crafted to ensure the best outcomes within a
            limited timeframe while providing a two-year guarantee of continued
            support after completion.
          </p>

          <b>Dedicated Team of Specialists</b>
          <p>
            The Elite Wellbeing team comprises a dedicated group of specialists,
            including doctors, therapists, and recovery coaches, all selected
            for their expertise in addiction treatment. This multi-disciplinary
            team works collaboratively to deliver the highest quality of care to
            participants, ensuring that they receive individualized attention
            and support throughout their recovery journey.
          </p>

          <b>Support and Family Involvement</b>
          <p>
            Regular therapy sessions and family support meetings are integral to
            the program, fostering a strong network that aids both participants
            and their families in navigating the challenges of addiction.
          </p>

          <b>Two-Year Guarantee on Treatment</b>
          <p>
            A key aspect of the Elite Wellbeing program is the two-year
            guarantee provided on treatment, contingent upon the fulfillment of
            specified terms and conditions. This guarantee offers peace of mind
            to participants and their families, ensuring that continued support
            and assistance are available even after the program concludes.
          </p>
          <p>
            This commitment to fostering sustainable recovery reflects the
            team’s belief in the participants' potential for lasting change.
          </p>

          <b>Our Goal</b>
          <p>
            Ultimately, the goal is to provide comprehensive, specialized care
            that ensures a comfortable and effective recovery journey, with the
            assurance of ongoing support to promote a life free from addiction.
          </p>
        </div>

        <RecoveryStages />

        <div className="flex flex-col gap-8 items-center">
          <div className="flex flex-col gap-4 items-center">
            <h3 className="font-bold text-xl lg:text-3xl">
              {t("titles.offers")}
            </h3>
            <small className="w-full md:w-3/4 m-auto text-center">
              Elite Wellbeing provides holistic addiction recovery services with
              a focus on supporting individuals through physical, psychological,
              and spiritual well-being. They offer personalized programs
              designed specifically for the Arab youth, helping individuals
              detox and overcome withdrawal symptoms. Their services include
              consultations for addiction treatment plans, continuous support
              throughout the recovery process, and expert guidance to help
              clients achieve long-lasting recovery.
            </small>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {ourOffers.map((offer, index) => (
              <OurValue
                key={index}
                desc={offer.description}
                images={offer.image}
                className={`${
                  index === ourOffers.length - 1
                    ? "md:col-span-2 md:justify-self-center md:w-1/2"
                    : ""
                }`}
              />
            ))}
          </div>
        </div>

        <div className="flex flex-col gap-8 items-center md:w-3/4 w-full mx-auto">
          <h3 className="font-bold text-2xl">{t("titles.our_values")}</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {ourValues.map((value, index) => (
              <OurValue
                key={index}
                icon={value.icon}
                title={value.name}
                desc={value.description}
                className={`${
                  index === ourValues.length - 1
                    ? "md:col-span-2 md:justify-self-center md:w-1/2"
                    : ""
                }`}
              />
            ))}
          </div>
        </div>
        <Testimonials />
        <ConsultationForm />
      </div>
    </div>
  );
});

export default EliteWellbeing;
