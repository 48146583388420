import { Button } from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const HeroPage = React.memo(({
  title,
  desc,
  btnTitle,
  btnPath,
  ratingData,
  heroImage,
}) => {
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  const handleResize = () => {
    // Check if the screen width is less than or equal to 768px
    setIsSmallScreen(window.innerWidth <= 768);
  };

  useEffect(() => {
    // Initial check
    handleResize();

    // Add event listener to update state on resize
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {isSmallScreen ? (
        <div
          className="bg-hero_image_bg w-full relative"
          style={{
            height: "calc(100vh - 80px)",
            backgroundImage: `url(${heroImage})`,
            backgroundSize: "cover",
            backgroundPosition: "top",
          }}
        >
          <div className="absolute inset-0 bg-black bg-opacity-50"></div>
          <div className="container mx-auto flex items-center h-full gap-4 text-white">
            <div className="flex flex-col justify-center gap-6 p-4 z-10">
              <span
                className="font-bold text-2xl"
                dangerouslySetInnerHTML={{ __html: title }}
              ></span>

              <p className="text-sm leading-normal">{desc}</p>

              {btnTitle && (
                <Link to={btnPath}>
                  <Button type="primary" className="w-fit">
                    {btnTitle}
                  </Button>
                </Link>
              )}

              <div className="flex items-center gap-4">
                {ratingData &&
                  ratingData.map((rate, index) => (
                    <div
                      key={index}
                      className="flex flex-col items-start gap-1 text-xs lg:text-base"
                    >
                      <b className="text-xl">{rate.value}</b>
                      <span className="flex-none w-max">{rate.name}</span>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          className="bg-hero_image_bg w-full"
          style={{
            height: "calc(100vh - 80px)",
          }}
        >
          <div className="container mx-auto block md:grid md:grid-cols-2 gap-4">
            <div className="flex flex-col justify-center gap-6 p-4">
              <span
                className="font-bold text-2xl md:text-3xl lg:text-5xl"
                dangerouslySetInnerHTML={{ __html: title }}
              ></span>
              <div className="">
                <p className="text-base leading-normal">{desc}</p>
              </div>

              {btnTitle && (
                <Link to={btnPath}>
                  <Button type="primary" className="w-fit">
                    {btnTitle}
                  </Button>
                </Link>
              )}

              <div className="flex items-center gap-8">
                {ratingData &&
                  ratingData.map((rate, index) => (
                    <div
                      key={index}
                      className="flex flex-col items-start gap-1 text-xs lg:text-base"
                    >
                      <b>{rate.value}</b>
                      <span>{rate.name}</span>
                    </div>
                  ))}
              </div>
            </div>
            <div
              className="hidden md:flex items-center justify-center"
              style={{ height: "calc(100vh - 80px)" }}
            >
              <img
                src={heroImage}
                alt="heroImage"
                className="w-full h-full object-cover object-top rounded-lg"
                loading="lazy"
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
});

export default HeroPage;
