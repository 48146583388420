import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { programsFunc, coachingData } from "../utils/constants";
import Team from "../components/shared/Team";
import { CrownOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

const SubProgramDetails = React.memo(() => {

  const { t } = useTranslation();

  const { programId, id } = useParams();

  const [item, setItem] = useState(null);
  const [coachingMembers, setCoachingMembers] = useState([]);

  useEffect(() => {
    const program = programs.find((item) => item.id === programId);
    const itemData = program.programData.subPrograms.find(
      (item) => item.id === id
    );

    if (programId === "2" && id === "3") {
      const filterCoachingData = coachingData.filter((item1) =>
        itemData.offerings.some((item2) => item1.name === item2.title)
      );
      setCoachingMembers(filterCoachingData);
    }
    setItem(itemData);
    window.scrollTo(0, 0);
  }, [id, programId]);

  const programs = programsFunc(t)

  return (
    <div className="flex flex-col gap-4 container">
      <h3 className="font-bold text-2xl text-highLight_color">{item?.name}</h3>
      {item?.duration ? (
        <div className="flex items-center gap-1">
          <b>Duration : </b>
          <span>{item.duration}</span>
        </div>
      ) : null}

      {item?.programComponents?.length ? (
        <div className="flex flex-col gap-4">
          <span className="text-base lg:text-xl">Program Components: </span>
          {item?.programComponents.map((offer, index) => (
            <div key={index} className="flex flex-col gap-2">
              <li className="ml-2">{offer?.title}</li>
              {offer?.subSessions.length
                ? offer?.subSessions.map((session, ind) => (
                    <li key={ind} className="ml-6 text-textGray">
                      {session}
                    </li>
                  ))
                : null}
            </div>
          ))}
        </div>
      ) : null}

      {item?.offerings?.length ? (
        <div className="flex flex-col gap-4">
          <span className="text-base lg:text-xl">What program offers: </span>
          {item?.offerings.map((offer, index) => (
            <div key={index} className="flex flex-col gap-2">
              <li
                className={`relative ml-2 ${
                  offer?.vip ? "text-vip_color" : ""
                }`}
              >
                {offer?.vip && (
                  <span className="absolute -left-2 -top-2 rotate-[-25deg] text-vip_color">
                    <CrownOutlined className="text-vip_color" />
                  </span>
                )}
                <span>{offer?.title}</span>
              </li>
              {offer?.subSessions.length
                ? offer?.subSessions.map((session, ind) => (
                    <li key={ind} className="ml-6 text-textGray">
                      {session}
                    </li>
                  ))
                : null}
            </div>
          ))}
        </div>
      ) : null}

      {programId === "2" && id === "3" && (
        <Team
          data={coachingMembers}
          title="Private sessions with the following individuals"
          inHome
        />
      )}
    </div>
  );
});

export default SubProgramDetails;
