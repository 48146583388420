import React from "react";

const TestimonialsItem = React.memo(({ item, inHome }) => {
  return (
    <>
      {inHome ? (
        <div className="bg-white rounded-xl p-4 flex flex-col gap-4 text-center shadow-md">
          <p>{item.content}</p>
        </div>
      ) : (
        <div className="bg-white rounded-xl p-4 flex flex-col gap-4 text-center">
          <b className="text-highLight_color">{item.title}</b>
          <p>{item.desc}</p>
        </div>
      )}
    </>
  );
});

export default TestimonialsItem;
