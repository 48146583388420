import { Form, Button, Input, message } from "antd";
import React, { useState } from "react";
import { MailOutlined, UserOutlined } from "@ant-design/icons";
import consultationImage from "../assets/images/WhatsApp Image 2024-11-07 at 14.51.24_215acdb7.webp";
import { PhoneInput  } from "react-international-phone";
import "react-international-phone/style.css";
import { PhoneNumberUtil } from "google-libphonenumber";

const ConsultationForm = React.memo(() => {
  const phoneUtil = PhoneNumberUtil.getInstance();
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const [phone, setPhone] = useState("");
  // const [isValid, setIsValid] = useState(true);
  const [countryCode, setCountryCode] = useState("");

  // Validate the phone number
  const validatePhone = (phone) => {
    try {
      const parsedPhone = phoneUtil.parseAndKeepRawInput(phone);
      return phoneUtil.isValidNumber(parsedPhone);
    } catch (error) {
      return false;
    }
  };

  // Handle phone input change
  const onFinish = async (values) => {
    if (validatePhone(phone)) {
      messageApi.open({
        type: "loading",
        content: "Sending Message..",
        duration: 2.5,
      });
      var formdata = new FormData();
      formdata.append("email", values.email);
      formdata.append("phone_number", phone);
      formdata.append("full_name", values.fullName);
      formdata.append("country_code", countryCode.toUpperCase());
      // Prepare API payload


      try {
        const response = await fetch(
          "https://api.restartorg.com/consultation/add_consultation/",
          {
            method: "POST",
            body: formdata,
          }
        );

        if (response.ok) {
          await response.json();
          message.success("Message Sent Successfully", 2.5);
          form.resetFields();
          setPhone(""); // Clear phone input
          setCountryCode(""); // Clear country code
        } else {
          const errorData = await response.json();
          console.error("Error Response:", errorData);
          message.error("Failed to send message. Please try again.");
        }
      } catch (error) {
        console.error("Error:", error);
        message.error("Something went wrong. Please try again.");
      }
    } else {
      message.error("Invalid phone number. Please check and try again.");
    }
  };

  // Handle form submission
  // const onFinish = (values) => {
  //   if (validatePhone(phone)) {
  //     messageApi
  //       .open({
  //         type: "loading",
  //         content: "Sending Message..",
  //         duration: 2.5,
  //       })
  //       .then(() => {
  //         // Add your API call logic here
  //         console.log("Form Values:", { ...values, phone, countryCode });
  //         message.success("Message Sent Successfully", 2.5);
  //       })
  //       .then(() => {
  //         form.resetFields();
  //         setPhone(""); // Clear phone input
  //       });
  //   } else {
  //     message.error("Invalid phone number. Please check and try again.");
  //   }
  // };

  return (
    <>
      {contextHolder}
      <div className="grid md:grid-cols-2 grid-cols-1 gap-8">
        <div className="max-h-80 h-80 md:block hidden">
          <img
            className="w-full h-full object-cover"
            src={consultationImage}
            alt="consultationImage"
            loading="lazy"
          />
        </div>
        <div className="flex flex-col gap-8 justify-center">
          <div className="flex flex-col gap-2">
            <b className="text-2xl">Need a free Consultation?</b>
            <small>
              Do you or a loved one suffer? Now you can ask for help from
              specialists to offer the great process that will find the better
              way for you.
            </small>
          </div>
          <Form form={form} onFinish={onFinish}>
            <Form.Item
              name="fullName"
              rules={[
                {
                  required: true,
                  message: "Please input your full name!",
                },
              ]}
            >
              <Input prefix={<UserOutlined />} placeholder="Your full name" />
            </Form.Item>

            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please input your Email!",
                },
              ]}
            >
              <Input prefix={<MailOutlined />} placeholder="Your Email" />
            </Form.Item>

            <Form.Item
              name="phone"
              rules={[
                {
                  required: true,
                  message: "Please input your phone number!",
                },
              ]}
            >
              <div className="w-full">
                <PhoneInput
                  defaultCountry="jo"
                  placeholder="Your Phone Number"
                  value={phone}
                  onChange={(phone, { country }) => {
                    setPhone(phone);
                    setCountryCode(country.iso2);
                  }}
                />

                  {/* <small className="text-red-500">Invalid phone number</small> */}

              </div>
            </Form.Item>

            <Form.Item>
              <Button block type="primary" htmlType="submit">
                Book Free Consultation
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </>
  );
});

export default ConsultationForm;
