import React from "react";
import { Button, Card, Tooltip } from "antd";
import { Link } from "react-router-dom";
import { t } from "i18next";
const { Meta } = Card;

const CardItem = React.memo(({ item }) => {
  function truncateText(text, wordLimit) {
    const words = text.split(" ");
    if (words.length <= wordLimit) {
      return text;
    }
    return words.slice(0, wordLimit).join(" ") + "...";
  }
  return (
    <Card
      hoverable
      className="relative group"
      cover={
        <div className="relative overflow-hidden">
          <img
            className="h-72 w-full object-cover object-top"
            alt="example"
            src={item.img}
            loading="lazy"
          />
          {/* Overlay on the image */}
          <div className="absolute inset-0 bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 flex items-center justify-center transition-opacity duration-300">
            <p className="text-white text-sm px-4 text-center">
              {truncateText(item.description, 20)}
            </p>
          </div>
        </div>
      }
      actions={[
        <Link to={`/${item.path}/${item.id}`}>
          <Button type="primary">{t('titles.read_more')}</Button>
        </Link>,
      ]}
    >
      <Meta
        title={
          <Tooltip title={item.name}>
            <div className="overflow-hidden whitespace-nowrap overflow-ellipsis">
              {item.name}
            </div>
          </Tooltip>
        }
        description={
          <div className="overflow-hidden whitespace-nowrap overflow-ellipsis max-w-[200px]">
            {item.title}
          </div>
        }
      />
    </Card>
  );
});

export default CardItem;
