import React from 'react';
import { WhatsAppOutlined } from '@ant-design/icons';

const WhatsAppIcon = React.memo(() => {
  return (
    <a
      href="https://wa.me/+905551675716"
      target="_blank"
      rel="noopener noreferrer"
      className="fixed z-50 right-4 top-1/2 transform -translate-y-1/2 bg-green-500 text-white rounded-full w-12 h-12 flex items-center justify-center shadow-lg transition-transform duration-300 hover:scale-110"
    >
      <WhatsAppOutlined className="text-2xl" />
    </a>
  );
});

export default WhatsAppIcon;
