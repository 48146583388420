import React, { useState, useRef } from "react";
import { useNavbarItems, ProgramItemsDropdown } from "../../utils/constants";
import RestartLogo from "../../utils/icons/RestartLogo";
import { Link, useLocation } from "react-router-dom";
import { Button, Dropdown } from "antd";
import { MenuOutlined, CloseOutlined, DownOutlined } from "@ant-design/icons";
import LangSwitch from "./LangSwitch";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const Navbar = React.memo(() => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);
  const location = useLocation();
  const currentPath = location.pathname;
  const { t } = useTranslation();

  const { isArabic } = useSelector((state) => ({
    isArabic: state.shared.isArabic,
  }));

  const navbarItem = useNavbarItems();

  const items = ProgramItemsDropdown(t);

  return (
    <div className="w-full h-20">
      <div className="container h-full flex items-center justify-between">
        <RestartLogo />

        {/* Desktop Menu */}
        <ul
          className={`hidden md:flex items-center justify-center gap-8 ${
            isArabic ? "flex-row-reverse" : "flex-row"
          }`}
          dir={isArabic ? "ltr" : ""}
        >
          {navbarItem.map((item) => (
            <li key={item.id}>
              <Link
                to={item.path}
                className={`${currentPath === item.path ? "font-bold" : ""}`}
              >
                {item.name}
              </Link>
            </li>
          ))}
          <Dropdown
            menu={{
              items
            }}
            trigger={["click"]}
          >
            <span
              className="flex items-center gap-2 cursor-pointer"
              onClick={(e) => e.preventDefault()}
            >
              <span>{t('header.our_programs')}</span>
              <DownOutlined
                style={{
                  fontSize: "10px",
                }}
              />
            </span>
          </Dropdown>
          <LangSwitch />
        </ul>

        <div className="items-center gap-4 hidden md:flex">
          {/* <LangSwitch /> */}
          <Link to="https://wa.me/+905551675716">
            <Button type="primary">{t("header.btn_title")}</Button>
          </Link>
        </div>

        {/* Burger Menu for small screens */}
        <div className="md:hidden flex items-center gap-8">
          <div className="flex items-center gap-4">
            <Button type="primary" onClick={() => setIsOpen(false)}>
              {t("header.btn_title_sm")}
            </Button>
          </div>

          <MenuOutlined
            onClick={() => setIsOpen(!isOpen)}
            className="text-2xl cursor-pointer"
          />
        </div>
      </div>

      {/* Mobile Menu */}
      <div
        ref={menuRef}
        className={`md:hidden fixed top-0 left-0 w-full h-full bg-white shadow-lg z-50 transition-transform duration-300 ease-in-out ${
          isOpen ? "translate-y-0" : "-translate-y-full"
        }`}
        style={{
          transform: isOpen ? "translateY(0)" : "translateY(-100%)",
          transition: "transform 0.3s ease-in-out",
        }}
      >
        {/* Close Button */}
        <div className="flex justify-end p-6">
          <CloseOutlined
            onClick={() => setIsOpen(false)}
            className="text-2xl cursor-pointer"
          />
        </div>

        {/* Menu Items */}
        <ul className="flex flex-col items-center p-6 gap-4">
          {navbarItem.map((item) => (
            <li key={item.id}>
              <Link
                to={item.path}
                onClick={() => setIsOpen(false)}
                className={`text-lg py-2 px-4 rounded transition-all duration-300 ${
                  currentPath === item.path
                    ? "bg-gray-800 text-white"
                    : "hover:bg-gray-800 hover:text-white"
                }`}
              >
                {item.name}
              </Link>
            </li>
          ))}
           <Dropdown
            menu={{
              items: items.map((item) => ({
                ...item,
                onClick: () => setIsOpen(false),
              })),
            }}
            trigger={["click"]}
          >
            <span
              className="flex items-center gap-2 cursor-pointer"
              onClick={(e) => e.preventDefault()}
            >
              <span>{t('header.our_programs')}</span>
              <DownOutlined
                style={{
                  fontSize: "10px",
                }}
              />
            </span>
          </Dropdown>
          <LangSwitch />
        </ul>
      </div>
    </div>
  );
});

export default Navbar;
