import { useTranslation } from "react-i18next";
import {
  Privacy,
  SincereIntention,
  HighAccuracy,
  Transparency,
} from "../utils/icons/icons";
import { Link } from "react-router-dom";

import trainingImage1 from "./../assets/images/WhatsApp Image 2024-11-04 at 11.46.14_abefa5de.webp";
import trainingImage2 from "./../assets/images/WhatsApp Image 2024-11-04 at 12.12.01_0a786a37.webp";
import trainingImage3 from "./../assets/images/WhatsApp Image 2024-11-04 at 11.47.30_9809b339.webp";
import trainingImage4 from "./../assets/images/WhatsApp Image 2024-11-04 at 11.52.03_22061e7f.webp";
import trainingImage5 from "./../assets/images/WhatsApp Image 2024-11-04 at 11.51.55_35d49608.webp";
import trainingImage6 from "./../assets/images/WhatsApp Image 2024-11-07 at 20.53.37_e4bf0b30.webp";
import supervisionImage1 from "./../assets/images/WhatsApp Image 2024-11-07 at 20.42.02_841ea5a3.webp";
import supervisionImage2 from "./../assets/images/WhatsApp Image 2024-11-07 at 20.42.03_bf838ce4.webp";
import supervisionImage3 from "./../assets/images/WhatsApp Image 2024-11-07 at 20.42.03_076de179.webp";
import supervisionImage4 from "./../assets/images/WhatsApp Image 2024-11-07 at 20.42.34_d76e9486.webp";

import medicalCareImage1 from "./../assets/images/WhatsApp Image 2024-11-07 at 20.22.21_bedc4dc3.webp";
import medicalCareImage2 from "./../assets/images/WhatsApp Image 2024-11-07 at 20.23.43_9676a40a.webp";
import medicalCareImage3 from "./../assets/images/WhatsApp Image 2024-11-07 at 20.23.44_c0e251c2.webp";
import medicalCareImage4 from "./../assets/images/WhatsApp Image 2024-11-07 at 20.23.44_6e759373.webp";
import medicalCareImage5 from "./../assets/images/WhatsApp Image 2024-11-07 at 20.23.45_9abd49cb.webp";

import lifeCoachImage1 from "./../assets/images/WhatsApp Image 2024-11-04 at 11.54.17_aa4c0dd4.webp";
import lifeCoachImage2 from "./../assets/images/WhatsApp Image 2024-11-04 at 11.58.37_d7b91a76.webp";
import lifeCoachImage3 from "./../assets/images/WhatsApp Image 2024-11-04 at 11.59.30_d93d86d3.webp";

import tripsImage1 from "./../assets/images/WhatsApp Image 2024-11-07 at 20.51.26_a5194756.webp";
import tripsImage2 from "./../assets/images/WhatsApp Image 2024-11-07 at 20.51.27_5adf49b1.webp";
import tripsImage3 from "./../assets/images/WhatsApp Image 2024-11-08 at 12.39.54_b4f48a1e.webp";
import tripsImage4 from "./../assets/images/WhatsApp Image 2024-11-08 at 12.39.56_44e6a505.webp";

import detoxImage1 from "./../assets/images/WhatsApp Image 2024-11-07 at 20.18.47_156d52ae.webp";
import detoxImage2 from "./../assets/images/WhatsApp Image 2024-11-07 at 20.18.57_d27724a2.webp";
import detoxImage3 from "./../assets/images/WhatsApp Image 2024-11-07 at 20.19.10_c4ec74cf.webp";
import detoxImage4 from "./../assets/images/WhatsApp Image 2024-11-07 at 20.19.20_e499d30c.webp";
import detoxImage5 from "./../assets/images/WhatsApp Image 2024-11-07 at 20.19.31_80a4e69f.webp";

import accommodationImage1 from "./../assets/images/WhatsApp Image 2024-11-07 at 21.45.13_c281d24c.webp";
import accommodationImage2 from "./../assets/images/WhatsApp Image 2024-11-07 at 21.45.14_efd9b1b7.webp";
import accommodationImage3 from "./../assets/images/WhatsApp Image 2024-11-07 at 21.45.14_df512dd2.webp";
import accommodationImage4 from "./../assets/images/WhatsApp Image 2024-11-07 at 21.45.14_42caec02.webp";
import accommodationImage5 from "./../assets/images/WhatsApp Image 2024-11-07 at 20.56.11_8b1770f0.webp";
import accommodationImage6 from "./../assets/images/WhatsApp Image 2024-11-07 at 20.56.11_44c72cac.webp";

import saadImage from "./../assets/images/5330335846819293793.webp";
import hozifaImage from "./../assets/images/5330335846819293789.webp";
import usamaImage from "./../assets/images/5330335846819293787.webp";
import younisImage from "./../assets/images/5330335846819293797.webp";
import ammarImage from "./../assets/images/WhatsApp Image 2024-11-03 at 23.00.03_64f500c2.webp";
import nashatImage from "./../assets/images/WhatsApp Image 2024-11-03 at 20.21.19_cb1e3271.webp";
import samiImage from "./../assets/images/WhatsApp Image 2024-11-07 at 16.07.12_27a0c0dd.webp";
import daniaImage from "./../assets/images/WhatsApp Image 2024-11-07 at 16.06.18_d00354c7.webp";
import giorgosImage from "./../assets/images/WhatsApp Image 2024-11-03 at 22.16.28_4866b7f7.webp";
import heshamImage from "./../assets/images/WhatsApp Image 2024-11-03 at 20.21.06_55ec33cb.webp";
import ahlamImage from "./../assets/images/5330335846819293799.webp";
import amrImage from "./../assets/images/WhatsApp Image 2024-11-03 at 20.17.58_26fd8112.webp";
import abdelrazzaqImage from "./../assets/images/5330335846819293800.webp";
import tahaImage from "./../assets/images/5330335846819293795.webp";
import issamImage from "./../assets/images/5330335846819293792.webp";
import rahmaImage from "./../assets/images/5330335846819293783.webp";
import doukiImage from "./../assets/images/WhatsApp Image 2024-11-09 at 16.27.21_98f5cc95.webp";
import tarekImage from "./../assets/images/WhatsApp Image 2024-11-09 at 16.28.19_45b92c36.webp";
import maramImage from "./../assets/images/5330335846819293784.webp";
import commentImage from "./../assets/images/1e6137f8639ce1c4c4254fb5911a2e43.webp";
import afterCareImage from "./../assets/images/WhatsApp Image 2024-11-04 at 12.39.02_a4e445e8.webp";
import programImage1 from "../assets/images/IMG_4411.webp";
import programImage2 from "../assets/images/WhatsApp Image 2024-11-07 at 14.48.30_9e8a5e0b.webp";
import courseImage1 from "../assets/images/WhatsApp Image 2024-11-07 at 14.56.48_8351cff7.webp";
import courseImage2 from "../assets/images/WhatsApp Image 2024-11-04 at 12.12.01_0a786a37.webp";
import courseImage3 from "../assets/images/WhatsApp Image 2024-11-01 at 23.57.21_9b97d2d3.webp";
import courseImage4 from "../assets/images/WhatsApp Image 2024-11-04 at 12.03.04_8c5dd114.webp";
import courseImage5 from "../assets/images/WhatsApp Image 2024-11-04 at 12.13.56_18f9d2b2.webp";
import courseImage6 from "../assets/images/WhatsApp Image 2024-11-04 at 11.55.21_f9e294cc.webp";

import user1 from "../assets/images/indian-asian-man-with-light-spring-jacket-looking-off-side-isolated-green-background-jpg-portrait-image_1017677-28779.webp";
import user2 from "../assets/images/smiling-adult-latino-man-with-brown-curly-hair-photo-portrait-casual-person-city-street-photorealistic-ai-generated-horizontal-illustration_107173-52024.webp";
import user3 from "../assets/images/showing-number-two-with-fingers_1187-324022.webp";
import user4 from "../assets/images/positive-man-on-clean-background-photo.webp";
import user5 from "../assets/images/positive-man-on-clean-background-photo (1).webp";
import user6 from "../assets/images/businessman-wearing-suit-smiling-camera-portrait-generative-ai_1000174-2720.webp";
import user7 from "../assets/images/ai-generated-portrait-of-a-young-man-no-facial-expression-facing-the-camera-isolated-white-background-ai-generative-photo.webp";
import user8 from "../assets/images/WhatsApp Image 2024-11-23 at 17.30.50_184ec207.webp";
import user9 from "../assets/images/WhatsApp Image 2024-11-23 at 17.30.50_d5512b08.webp";
import user10 from "../assets/images/WhatsApp Image 2024-11-23 at 17.30.51_070c87e2.webp";
import user11 from "../assets/images/WhatsApp Image 2024-11-23 at 17.30.51_75e048b1.webp";
import user12 from "../assets/images/WhatsApp Image 2024-11-23 at 17.30.52_89d4d671.webp";
import user13 from "../assets/images/WhatsApp Image 2024-11-23 at 17.30.52_91e6c61f.webp";
import user14 from "../assets/images/WhatsApp Image 2024-11-23 at 17.30.53_3c4ffe79.webp";
import user15 from "../assets/images/resize.webp";
import user16 from "../assets/images/image2121212s.webp";
import user17 from "../assets/images/most-attractive-man-in-a-country-v0-9pwswqjkat8b1.webp";
import user18 from "../assets/images/100k-ai-faces-6.webp";

import phase1 from "../assets/images/WhatsApp Image 2024-11-07 at 15.19.33_119caaab.webp";
import phase2 from "../assets/images/WhatsApp Image 2024-11-04 at 11.56.17_a7219ec5.webp";
import phase3 from "../assets/images/WhatsApp Image 2024-11-04 at 11.51.01_92675b1a.webp";

import blogImage1 from "../assets/images/blog1.webp";
import blogImage2 from "../assets/images/blog2.webp";
import blogImage3 from "../assets/images/blog3.webp";
import blogImage4 from "../assets/images/blog4.webp";
import blogImage5 from "../assets/images/blog5.webp";
import blogImage6 from "../assets/images/blog6.webp";
import blogImage7 from "../assets/images/blog7.webp";
import blogImage8 from "../assets/images/blog8.webp";
import blogImage9 from "../assets/images/blog9.webp";
import blogImage10 from "../assets/images/blog10.webp";
import blogImage11 from "../assets/images/blog11.webp";
import blogImage12 from "../assets/images/blog12.webp";
import blogImage13 from "../assets/images/blog13.webp";
import blogImage14 from "../assets/images/blog14.webp";
import blogImage15 from "../assets/images/blog15.webp";
import blogImage16 from "../assets/images/blog16.webp";
import blogImage17 from "../assets/images/blog17.webp";
import blogImage18 from "../assets/images/blog18.webp";

export const useNavbarItems = () => {
  const { t } = useTranslation();

  return [
    { id: 1, name: t("header.home"), path: "/" },
    { id: 6, name: t("header.about_us"), path: "/our_story" },
    { id: 4, name: t("header.our_team"), path: "/our-team" },
    { id: 2, name: t("header.elite_wellbeing"), path: "/elite_wellbeing" },
    // { id: 3, name: t("header.success_story"), path: "/success_story" },
    { id: 5, name: t("header.blog"), path: "/blogs" },
  ];
};

export const ProgramItemsDropdown = (t) => {
  return [
    {
      label: <Link to="/program">{t("titles.allPrograms")}</Link>,
      key: "4",
    },
    {
      label: <Link to="/program/1">{t("titles.familyProgram")}</Link>,
      key: "0",
    },
    {
      label: <Link to="/program/2">{t("titles.onlineProgram")}</Link>,
      key: "1",
    },
    {
      label: <Link to="/program/3">{t("titles.afterCareProgram")}</Link>,
      key: "2",
    },
    {
      label: <Link to="/program/4">{t("titles.detox")}</Link>,
      key: "3",
    },
  ];
};

export const ourValues = [
  {
    id: 1,
    icon: Privacy(),
    name: "Privacy",
    description:
      "and complete confidentiality in dealing with patients and their information.",
  },
  {
    id: 2,
    icon: Privacy(),
    name: "Commitment",
    description:
      "to applying the latest scientific information in the addiction treatment journey.",
  },
  {
    id: 3,
    icon: SincereIntention(),
    name: "Sincere intention",
    description: "in helping patients with addiction and their families.",
  },
  {
    id: 4,
    icon: HighAccuracy(),
    name: "high accuracy",
    description:
      "Dealing with and professionalism according to ethical principles.",
  },
  {
    id: 5,
    icon: Transparency(),
    name: "Transparency",
    description: "in vision, goals, and purpose.",
  },
];

export const ourOffers = [
  {
    id: 1,
    name: "Privacy",
    image: [detoxImage1, detoxImage2, detoxImage3, detoxImage4, detoxImage5],
    description:
      "Detoxification and control of withdrawal symptoms in the best facilities in Istanbul, with the possibility of hospital work outside.",
  },
  {
    id: 2,
    name: "Commitment",
    image: [
      medicalCareImage1,
      medicalCareImage2,
      medicalCareImage3,
      medicalCareImage4,
      medicalCareImage5,
    ],
    description:
      "Intensive medical care from specialists, including all necessary laboratory tests and continuous medical supervision.",
  },
  {
    id: 3,
    name: "Sincere intention",
    image: [
      supervisionImage1,
      supervisionImage2,
      supervisionImage3,
      supervisionImage4,
    ],
    description:
      "Supervision by specialists in various fields applying the latest treatment methods, fluent in Arabic and of Arab origin.",
  },
  {
    id: 7,
    name: "Transparency",
    image: [
      accommodationImage1,
      accommodationImage2,
      accommodationImage3,
      accommodationImage4,
      accommodationImage5,
      accommodationImage6,
    ],
    description:
      "Accommodation in a resort surrounded by nature with beautiful views to ensure the highest levels of psychological and mental comfort, including a private car and driver for each individual.",
  },
  {
    id: 4,
    name: "high accuracy",
    image: [lifeCoachImage1, lifeCoachImage2, lifeCoachImage3],
    description:
      "Life coach available throughout the recovery period for life skills training.",
  },
  {
    id: 5,
    name: "Transparency",
    image: [tripsImage4, tripsImage3, tripsImage1, tripsImage2],
    description:
      "Touristic trips in different cities in Turkey to explore every weekend.",
  },
  {
    id: 6,
    name: "Transparency",
    image: [
      trainingImage1,
      trainingImage2,
      trainingImage3,
      trainingImage4,
      trainingImage5,
      trainingImage6,
    ],
    description:
      "Life skills training with a focus on personal development through daily varied activities.",
  },
];

export const breakpoints = {
  640: {
    slidesPerView: 2,
    spaceBetween: 10,
  },
  768: {
    slidesPerView: 3,
    spaceBetween: 10,
  },
  1024: {
    slidesPerView: 4,
    spaceBetween: 20,
  },
};

export const coachingData = [
  {
    name: "Saad Al-Mahmoud",
    title:
      "Founder of the Restart Team and Expert in Recovery and Mental Health",
    img: saadImage,
    path: "details",
    category: "recovery",
    id: "1",
    description:
      "Saad is the founder of the Restart team, bringing a wealth of expertise in recovery coaching and mental health support.Saad holds a master’s degree in Clinical Mental Health Counseling from Liberty University. He is also a certified nutrition specialist through ISSA, a personal trainer, and a professional recovery coach trained by the Net Institute.With this extensive background, Saad integrates his knowledge of mental health, physical fitness, and nutrition to provide holistic and personalized support for individuals overcoming addiction. His approach emphasizes practical recovery tools, a focus on resilience, and a balanced, sustainable path to wellness.",
  },
  {
    name: "Dr.Huzeyfe Barham",
    title:
      "M.D. - Psychiatrist Specializing in Mental Health and Addiction Treatment",
    img: hozifaImage,
    path: "details",
    category: "psychiatrist",
    id: "2",
    description:
      "He is a distinguished psychiatrist specializing in mental health disorders, including depression, anxiety, and addiction. He earned his Doctor of Medicine degree from Istanbul University, Cerrahpaşa Medical Faculty, in 2014, followed by a psychiatry residency, which he completed in 2019. Dr. Barham is the founder of the Shadow Clinic, where he provides comprehensive care for individuals struggling with mental health issues. He is also an active member of the Restart team, where he applies his extensive knowledge in holistic addiction treatment. His approach incorporates various psychotherapeutic techniques, including cognitive behavioral therapy (CBT) and dialectical behavioral therapy (DBT), alongside a strong emphasis on social rehabilitation and family involvement in the treatment process. Dr. Barham’s commitment to addressing the complex nature of addiction and mental health allows him to offer tailored support and effective recovery strategies for individuals on their journey to wellness.",
  },
  {
    name: "Younis Abu Hashish",
    title: "A clinical psychologist Specializing in Mental Health",
    img: younisImage,
    path: "details",
    category: "psychologist",
    id: "3",
    description:
      "Younis Abu Hashish is a dedicated clinical psychologist with a Master’s degree in Mental Health and Psychological Well-being. With over 10 years of experience in addressing various psychological disorders, he specializes in Cognitive Behavioral Therapy (CBT), EMDR, and resilience-based therapy.His therapeutic sessions focus on the intricate relationship between thoughts, emotions, and behaviors. Younis empowers his clients by teaching them techniques and tools to identify and analyze negative thoughts and distorted perceptions. He guides individuals in transforming unhelpful behaviors into positive actions, providing invaluable support for those seeking to overcome addiction and improve their mental well-being.Younis’s compassionate approach and extensive expertise make him a trusted ally in the journey toward healing and recovery, helping individuals build a brighter and healthier future.",
  },
  {
    name: "Dr. Tariq Hajawi",
    title: "Psychiatrist and Addiction Specialist",
    img: tarekImage,
    path: "details",
    category: "psychiatrist",
    id: "20",
    description:
      "Dr. Tariq Hajawi is a consultant psychiatrist specializing in addiction treatment. He earned his Bachelor of Medicine and Surgery from the University of Jordan and ranked first in the Arab Board Exam for Psychiatry in the UAE in 2013. Dr. Hajawi holds both the Jordanian Board in Psychiatry and the American Board in General Medicine, as well as advanced training in psychiatric specialization.He has extensive experience, having worked as a consultant psychiatrist at King Abdullah University Hospital and served as an accredited consultant for the International Medical Corps. Additionally, he has been a lecturer at the Faculty of Medicine at Jordan University of Science and Technology. Currently, Dr. Hajawi runs his private practice, 'Jannati Clinic,' in Amman and Irbid, where he provides psychological consultations, addiction treatment, and specialized training programs.",
  },
  {
    name: "Osama Al-Zahouri",
    title: "Recovery Coach and Fitness Trainer",
    img: usamaImage,
    path: "details",
    category: "recovery",
    id: "4",
    description:
      "Osama Al-Zahouri is a dedicated recovery coach and life coach, holding a diploma in Addictive Behavioral Management. He is also a certified fitness and nutrition trainer for athletes, having graduated with a diploma from IFPA. In addition to his coaching expertise, Osama serves as the Program Manager for Turkey’s internal affairs, where he oversees program implementation and ensures effective support for participants. With a commitment to fostering recovery and promoting wellness, Osama is dedicated to helping individuals achieve their goals in both fitness and life.",
  },
  {
    name: "Rahma Al-Sartawi",
    title: "Clinical Pharmacist and Mental Health Advocate",
    img: rahmaImage,
    path: "details",
    category: "management",
    id: "11",
    description:
      "Rahma Al-Sartawi is a skilled pharmacist with extensive experience in both community and clinical pharmacy settings, specializing in managing psychiatric medications and demonstrating a strong interest in mental health. As the Online Programs Manager, Rahma oversees workflow, sessions, and follow-ups. She is responsible for documenting files for each participant, ensuring consistent follow-up with individuals and their families. In her role, she coordinates parental programs and sessions, actively engaging with families to support their involvement in the treatment process. Rahma is also responsible for the 100-Day Challenge, where she helps participants achieve their goals through structured support and guidance. Additionally, she handles logistical and administrative tasks within the team, ensuring seamless coordination and effective communication. With her dedication to mental health and commitment to quality care, Rahma is an invaluable asset to the healthcare community.",
  },
  {
    name: "Issam Al-Zaitouni",
    title: "A Visionary in Fitness and Holistic Wellness",
    img: issamImage,
    path: "details",
    category: "fitness",
    id: "5",
    description:
      "Issam Al-Zaitouni is a certified professional trainer specializing in fitness, movement correction, and body structuring. With a deep commitment to fostering a balanced lifestyle, he emphasizes the integration of physical, mental, emotional, professional, and spiritual aspects in his training approach. Issam has personally navigated significant challenges, including addiction, obesity, toxic relationships, and various forms of anxiety. These experiences have profoundly shaped his understanding of the human experience, allowing him to develop a comprehensive methodology that combines physical fitness with psychological and spiritual growth. His insights empower individuals to set and achieve meaningful goals while promoting overall well-being. Dedicated to creating sustainable self-improvement strategies, Issam aims to help individuals transform their pain into strength, enabling them to reach their full potential without negatively impacting those around them. His holistic approach not only fosters individual growth but also encourages a supportive community for lasting positive change.",
  },
  {
    name: "Ammar",
    title: "Addiction Recovery Specialist",
    img: ammarImage,
    path: "details",
    category: "recovery",
    id: "12",
    description:
      "Ammar is a dedicated recovery coach specializing in cannabis and marijuana addictions. He offers personalized one-on-one sessions to help individuals overcome addiction challenges and promote inner balance. Using effective techniques, Ammar provides the tools necessary for managing life and enhancing recovery. He creates a safe, supportive environment for individuals to explore themselves and achieve genuine healing.",
  },
  {
    name: "Ahlam Al Azkawi",
    title: "Family Coordinator and Trainer",
    img: ahlamImage,
    path: "details",
    category: "familyCoach",
    id: "15",
    description:
      "Ahlam Al Azkawi specializes in supporting families of young individuals struggling with addiction. She develops tailored guidance plans and provides training to parents, equipping them with the tools and strategies needed to support their children through the treatment journey. Ahlam’s work focuses on fostering understanding and effective communication, helping families navigate this challenging process with confidence and compassion.",
  },
  {
    name: "Dania Al-Balbisi",
    title: "Medical Graduate and Healthcare Specialist",
    img: daniaImage,
    path: "details",
    category: "management",
    id: "10",
    description:
      "Dania Al-Balbisi graduated with a Doctor of Medicine degree. Fluent in Arabic and English, she brings strong communication skills to her medical practice. She has completed courses in first aid, wound care, and suturing, equipping her with essential clinical skills. In addition, Dania graduated from the British program with impressive qualificationssages and medications and prepares detailed reports. Furthermore, she plays a vital role in ensuring smooth transitions for patients relocating to Turkey, including facilitating admission protocols.",
  },

  {
    name: "Sami Al-Baziri",
    title: "Dedicated Nurse and Personal Trainer",
    img: samiImage,
    path: "details",
    category: "recovery",
    id: "7",
    description:
      "Sami Al-Baziri holds a Bachelor’s degree in Nursing and is a certified personal trainer by ISSA, bringing over five years of experience in both fields. He also possesses a diploma in Addictive Behavioral Management, with more than four years of practical experience in this specialized area. Sami believes in the power of resilience, stating, “Failure is simply the opportunity to begin again, this time more intelligently.” He actively works with the 100-Day Challenge program, where he serves as the Head Nurse, responsible for designing daily schedules and weekly plans tailored to help participants achieve optimal results. In addition to his training and nursing roles, Sami conducts sessions on addictive behaviors and relapse prevention, focusing on creating entirely new lifestyle systems for individuals seeking recovery. His extensive experience and dedication make him a valuable asset in the fields of nursing and personal training.",
  },
  {
    name: "Maram Bataineh",
    title: "Pharmacist and Social Justice Advocate",
    img: maramImage,
    path: "details",
    category: "management",
    id: "6",
    description:
      "Maram Bataineh is a dedicated pharmacist, with a strong background in clinical practice and community service. She earned a Human Rights certificate from Saint Mary’s College in Indiana and was recognized as the Best Socially Erasmus Student by ESN for her advocacy work. Maram is  supporting individuals struggling with addiction. She has held various roles, including program advisor, administrative assistant in the Turkey Camp Rehabilitation Program.also   providing care to customers and partners in Jordan's Elite Program.",
  },

  {
    name: "Nashat Yildirir",
    title: "Supervisor of Housing, Hospitality, and Pharmacy",
    img: nashatImage,
    path: "details",
    category: "management",
    id: "8",
    description:
      "Nashat is a dedicated supervisor responsible for housing, hospitality, and pharmacy operations. He plays a crucial role in managing accommodations and ensuring a comfortable environment for residents. Additionally, he assists with any matters requiring proficiency in the Turkish language, facilitating effective communication and problem-solving. With his expertise and attention to detail, Nashat ensures that all needs are met efficiently, contributing to a positive experience for everyone involved.",
  },
  // {
  //   name: "Dr. Rami Al-Amoush",
  //   title: "General Practitioner and Advocate for Mental Health",
  //   img: saadImage,
  //   path: "details",
  //   id: "9",
  //   description:
  //     "Dr. Rami Al-Amoush is a dedicated general practitioner with a strong focus on mental health and addiction treatment. His goal is to become a psychiatrist to help change societal perceptions surrounding these important issues. Dr. Rami has undergone extensive training in psychology under the guidance of various esteemed doctors across different psychiatric departments. Additionally, he is a certified full-training coach with Saad Al-Mahmoud and specialists from the RestartUrself team, equipping him with the skills to address your inquiries and guide you toward the appropriate treatment pathways. With a commitment to fostering understanding and support in mental health, Dr. Rami is dedicated to making a positive impact in the community.",
  // },

  {
    name: "Giorgos Tellawi",
    title: "Program Advisor Team Leader at Restart",
    img: giorgosImage,
    path: "details",
    category: "management",
    id: "13",
    description:
      "Giorgos Tellawi serves as the program advisor team leader at RestartUrSelf and is currently pursuing a master’s degree in precision medicine in Europe. His work focuses on unraveling the molecular mechanisms behind neuropsychiatric disorders, with a vision to improve diagnostic and treatment strategies based on patients’ unique genetic profiles. Giorgos is driven by the desire to eliminate the trial-and-error approach often seen in psychiatric treatment, which can lead to side effects, frustration, and, in some cases, addiction. Having witnessed many patients struggle with addiction due to misdiagnosis, he is deeply motivated to contribute to the development of more precise and effective treatments from the outset, empowering individuals to achieve better mental health outcomes.",
  },
  {
    name: "Hesham Abu Tayeh",
    title: "Logistics Coordinator at Turkey Camp",
    img: heshamImage,
    path: "details",
    category: "management",
    id: "14",
    description:
      "Hesham Abu Tayeh manages deliveries and fulfills all logistical needs for the Turkey camp. He is responsible for airport pickups and drop-offs, ensuring smooth transportation for arrivals and departures. Additionally, Hesham oversees household inventory checks and supplies, ensuring all necessary items are readily available to support the camp’s operations.",
  },

  {
    name: "Amr Saqfelhait",
    title: "Media and Marketing Strategy Director",
    img: amrImage,
    path: "details",
    category: "management",
    id: "16",
    description:
      "Amr Saqfelhait is responsible for overseeing media operations and developing marketing strategies. His role involves crafting impactful media content and implementing effective marketing plans to enhance the organization’s reach and engagement. Amr’s strategic approach ensures that messaging aligns with organizational goals, strengthening its presence and connection with the target audience.",
  },
  {
    name: "Abdelrazzaq Al-Nsour",
    title: "Bodybuilding and Fitness Coach",
    img: abdelrazzaqImage,
    path: "details",
    category: "fitness",
    id: "17",
    description:
      "Since 2015, Abdelrazzaq Al-Nsour has been a dedicated bodybuilding and fitness coach, certified through ISSA and with specialized sports nutrition training from Al-Ahliyya Amman University (Levels 1 and 2). He has also competed and won several local and international bodybuilding championships.In his role with the team, Abdelrazzaq oversees the Jordan Program, managing all logistical and organizational aspects for both participants and team members. He is also responsible for guiding participants in their physical training, monitoring their fitness routines, and ensuring they follow appropriate nutrition plans. Currently, he is expanding his expertise by studying addictive behavior management.",
  },
  {
    name: "Taha Nwailah",
    title: "Program Advisor",
    img: tahaImage,
    path: "details",
    category: "management",
    id: "18",
    description:
      "Taha Nwailah is a Program Advisor at RestartUrSelf, where he develops personalized recovery plans for individuals facing addiction. He provides ongoing support and resources, taking a holistic approach that integrates mental health and nutrition. Taha is dedicated to empowering clients to achieve their recovery goals and foster personal growth.",
  },
  {
    name: "Mohammed Al-Douki",
    title: "Spiritual Coach and 12-Step Program Coordinator",
    img: doukiImage,
    path: "details",
    category: "recovery",
    id: "19",
    description:
      "Mohammed Al-Douki holds a diploma from the Regional Center for Social Research, Development, and Human Rights, specializing as a behavioral and social counselor. With over ten years of experience in addiction treatment, particularly in dual diagnosis (addiction and mental disorders), he has developed a deep understanding of the complexities involved in recovery. Mohammed is an active member of the Anonymous Addicts Fellowship for ten years and has received recognition from the Al-Risala Charity for his contributions to addiction recovery initiatives. As a member of the Restart team, he specializes in the 12-Step recovery program, focusing on spiritual growth and individual therapy. Currently, he is a psychology student at Cairo University and has a background in sports, having previously won a bodybuilding championship in Cairo.",
  },
];

export const comments = [
  {
    image: commentImage,
    content:
      "I was so naughty and impulsive and with the love of adventures and anything in disasters that I got into, literally naughty and I was curious to try anything in the world, and I drank alcohol and loved doing Joe. My concern was how to stay up and be lined and I smoked weed or whatever I was traveling. After suddenly my friend said in the beans and shaw your body in the haram drink this treatment leave the drink and try Had... I agreed. Honestly I was Schaev it made sense and was Nirontaine on her time I actually tried after an hour of joy!! Hon Balsht's predecessor and the days increased in an unspeakably terrible way, because it was hard to get out of it, and I developed a lot, and I went back to drinking and I got into all the drug varieties. reed more",
    age: "20",
    rate: 4,
    member: "Olivia Rhya",
  },
  {
    image: commentImage,
    content:
      "I was so naughty and impulsive and with the love of adventures and anything in disasters that I got into, literally naughty and I was curious to try anything in the world, and I drank alcohol and loved doing Joe. My concern was how to stay up and be lined and I smoked weed or whatever I was traveling. After suddenly my friend said in the beans and shaw your body in the haram drink this treatment leave the drink and try Had... I agreed. Honestly I was Schaev it made sense and was Nirontaine on her time I actually tried after an hour of joy!! Hon Balsht's predecessor and the days increased in an unspeakably terrible way, because it was hard to get out of it, and I developed a lot, and I went back to drinking and I got into all the drug varieties. reed more",
    age: "20",
    rate: 4,
    member: "Olivia Rhya",
  },
  {
    image: commentImage,
    content:
      "I was so naughty and impulsive and with the love of adventures and anything in disasters that I got into, literally naughty and I was curious to try anything in the world, and I drank alcohol and loved doing Joe. My concern was how to stay up and be lined and I smoked weed or whatever I was traveling. After suddenly my friend said in the beans and shaw your body in the haram drink this treatment leave the drink and try Had... I agreed. Honestly I was Schaev it made sense and was Nirontaine on her time I actually tried after an hour of joy!! Hon Balsht's predecessor and the days increased in an unspeakably terrible way, because it was hard to get out of it, and I developed a lot, and I went back to drinking and I got into all the drug varieties. reed more",
    age: "20",
    rate: 4,
    member: "Olivia Rhya",
  },
];

export const programsFunc = (t) => {
  return [
    {
      name: t("titles.familyProgram"),
      desc: "The Family Program supports families dealing with addiction or mental health challenges. It educates family members about addiction and its impact, while fostering healthy communication. The program provides strategies to manage stress and build a supportive recovery environment. It strengthens family bonds and reduces conflicts, making the family a key partner in the recovery journey.",
      id: "1",
      path: "program",
      image: programImage1,
      title: "Empower Families Program ( 10-Day Intensive program )",
      link: "https://wa.me/+905551675716",
      programData: {
        subPrograms: [],
        description:
          "The Empower Families Program is designed to support families facing addiction challenges by providing a focused approach to understanding and addressing addiction, combining psychological counseling, medical evaluation, and life coaching. This program is designed to assess the individual's condition, create a personalized treatment plan, and introduce appropriate therapies.",
        offerings: [
          {
            title: "Two Sessions with Recovery Specialist Saad Al Mahmoud",
            details:
              "These sessions will focus on understanding your unique addiction challenges and setting the foundation for your recovery journey.",
          },
          {
            title: "Session with the Psychiatrist",
            details:
              "A consultation with the team’s psychiatrist to assess your mental health and discuss any necessary medical interventions.",
          },
          {
            title: "Two Extended Sessions with the Psychologist",
            details:
              "These in-depth therapeutic sessions with the team’s psychologist will address the emotional and psychological aspects of your addiction, offering tools and strategies for coping and healing.",
          },
        ],
        keyDeliverables: [
          {
            title: "Comprehensive Recovery Plan",
            details:
              "A fully customized recovery plan detailing each step of your treatment, designed specifically for your individual circumstances.",
          },
          {
            title: "Access to Experts",
            details:
              "Contact details of all involved doctors, therapists, and specialists will be provided, along with recommended treatment facilities.",
          },
          {
            title: "Resources for Recovery",
            details:
              "You will receive a curated list of courses, books, and other tools to support your recovery process.",
          },
          {
            title: "WhatsApp Follow-Up",
            details:
              "Continuous support for 10 days via WhatsApp, allowing you to ask questions and receive updates or modifications to your recovery plan.",
          },
        ],
        callToAction:
          "Don't miss the chance to be part of the supportive network. Join us now and take the first step toward healing and growth!",
      },
    },
    {
      name: t("titles.onlineProgram"),
      desc: "Online programs offer an innovative solution for individuals seeking recovery in a flexible and convenient way. These programs provide access to online therapy sessions, including psychological counseling and behavioral rehabilitation. They also offer educational resources, personalized treatment plans, and tools for stress management and building healthy habits. The service focuses on fostering sustainable recovery by delivering professional support and continuous progress monitoring without the need for in-person attendance.",
      image: programImage2,
      id: "2",
      path: "program",
      link: "https://wa.me/+905551675716",
      title: null,
      programData: {
        subPrograms: [
          {
            id: "1",
            path: "program-details",
            name: "10-Day Personalized Recovery Program",
            description:
              "This 10-day program offers a comprehensive, personalized approach to understanding and overcoming addiction. The program includes sessions with doctors, experts, and specialists to create a detailed, tailored recovery plan that fits your specific needs.",
            offerings: [
              {
                title: "Comprehensive Recovery Plan:",
                subSessions: [
                  "A fully customized recovery plan that addresses your specific circumstances, providing detailed steps for your treatment journey.",
                ],
              },
              {
                title: "Access to Experts:",
                subSessions: [
                  "You will receive contact details for all relevant doctors, therapists, and trainers involved in your recovery process, as well as recommended facilities.",
                ],
              },
              {
                title: "Scientific Nutrition Plan:",
                subSessions: [
                  "A detailed, personalized nutrition plan designed by a specialist, tailored to your health and recovery needs.",
                ],
              },
              {
                title: "Personalized Fitness Plan:",
                subSessions: [
                  "A fitness routine that aligns with your physical condition, helping to boost your overall well-being during recovery.",
                ],
              },
              {
                title: "Resources for Recovery:",
                subSessions: [
                  "You’ll be provided with a list of recommended courses, books, and helpful tools to support your journey to sobriety.",
                ],
              },
              {
                title: "WhatsApp Follow-Up:",
                subSessions: [
                  "For 10 days, you’ll have access to ongoing support via WhatsApp, where you can ask questions and receive updates or adjustments to your recovery plan.",
                ],
              },
            ],
            programComponents: [
              {
                title: "Two Sessions with Recovery Specialist Saad Al Mahmoud:",
                subSessions: [
                  "These sessions will focus on understanding your unique addiction challenges and setting the foundation for your recovery journey.",
                ],
              },
              {
                title: "Psychiatrist Session:",
                subSessions: [
                  "A session with the team’s psychiatrist to evaluate your mental health and explore any necessary medical interventions.",
                ],
              },
              {
                title: "Extended Session with the Psychologist:",
                subSessions: [
                  "A deep, therapeutic session with the team’s psychologist to address emotional and psychological factors contributing to your addiction.",
                ],
              },
              {
                title:
                  "Extended Session with the Fitness Trainer and Nutrition Specialist:",
                subSessions: [
                  "A personalized session to design a scientifically backed nutrition plan and a fitness routine that suits your condition and recovery goals.",
                ],
              },
            ],
          },
          {
            id: "2",
            path: "program-details",
            name: "The 100-Day Challenge Program",
            description:
              "Supervision: This challenge is supervised and approved by a certified psychologist and therapist to ensure participants' eligibility. Additional Discount: 20% discount for students. Contribution: A large portion of your participation fee supports our mission to assist 1 million people on their recovery journey who cannot afford treatment and helps build the RESTART recovery community.",
            offerings: [
              {
                title: "10-Day Assessment & Full Recovery Plan Development:",
                subSessions: [
                  "Two Assessment Sessions: Diagnostic sessions with a specialized therapist and Saad Al-Mahmoud to understand your addiction problem. A psychiatrist may also be consulted if necessary.",
                  "Session with a Nutrition and Fitness Expert: Evaluation of your physical health to ensure the development of a suitable nutrition and exercise plan tailored to your circumstances.",
                  "Complete Recovery Plan: A personalized and detailed recovery roadmap designed specifically for your situation.",
                  "Access to Professionals: You will receive a comprehensive list of therapists, doctors, trainers, and locations required in your recovery plan.",
                  "Resources: Access to courses, books, and support schedules relevant to your recovery journey.",
                  "10 Days of Intensive Follow-Up: Personalized support via WhatsApp with Saad Al-Mahmoud and team members to answer any questions and make adjustments to the plan.",
                ],
              },
              {
                title: "30-Day Intensive Group Recovery Program:",
                subSessions: [
                  "12 Group Sessions (3 sessions per week), including: 4 Group Therapy Sessions: Focused on your specific type of addiction, with a specialized addiction therapist, once a week. 4 Group 12-Step Program Sessions: Led by 12-step expert therapist, Mohammed Al-Dawaki, once a week. 4 Group Support Sessions: Exclusive to challenge participants, with Saad Al-Mahmoud and the RESTART team, once a week.",
                  "Daily Group Support: A dedicated WhatsApp group for daily communication with Saad and therapists, with responses within 12 hours.",
                  "Daily Fitness and Nutrition Support: Direct communication with the fitness coach and nutrition specialist.",
                ],
              },
              {
                title: " 60-Day Recovery Strengthening Program:",
                subSessions: [
                  "24 Group Sessions (3 sessions per week), including: 8 Cognitive Behavioral Therapy (CBT) Sessions: Focused on life rebuilding and correcting mistakes, led by a behavioral therapist. 8 Group 12-Step Program Sessions: Continuation and application of the 12 steps with Mohammed Al-Dawaki. 8 Weekly Support Group Sessions: Focused on relapse prevention and recovery reinforcement with Saad Al-Mahmoud and the RESTART team.",
                  "Daily Support: Ongoing support via WhatsApp with responses within 24 hours.",
                  "Daily Personal Support: Regular communication with your fitness coach and nutrition specialist for any questions and guidance.",
                ],
              },
              {
                title: "Personalized Fitness and Nutrition Program:",
                subSessions: [
                  "Customized Nutrition Plan: A flexible, science-based nutrition plan tailored to your goals, provided by a team nutritionist.",
                  "Tailored Fitness Plan: A personalized fitness plan designed according to your conditions and guided by a professional trainer.",
                  "Daily Follow-Up: Personal daily follow-up with the trainer to explain exercises, nutrition plans, and answer any questions throughout the 100-day challenge.",
                  "Meal Recommendations: A list of restaurants and meals in your city that align with your nutrition plan.",
                  "Shopping List: A detailed shopping list to help prepare your meals.",
                ],
              },
              {
                title: "Relapse Program:",
                subSessions: [
                  "Personal Call with Saad Al-Mahmoud: To understand the reasons for relapse.",
                  "10 Days of Intensive Follow-Up: Immediate support from our team to ensure you get back on track and stick to the recovery plan after a relapse.",
                ],
              },
              // vip
              {
                title: "Additional Support:",
                vip: true,
                subSessions: [
                  "Intensive follow-up with Saad Al-Mahmoud and your chosen therapist, including 30 personal therapy sessions that can be used within a year.",
                ],
              },
              {
                title: "Year-Long Support:",
                vip: true,
                subSessions: [
                  "Saad Al-Mahmoud will personally oversee your recovery journey with continuous follow-up for one full year.",
                ],
              },
            ],
            programComponents: [],
          },
          {
            id: "3",
            path: "program-details",
            name: "Private sessions with the following individuals",
            description:
              "At RestartUrSelf, our dedicated team of trainers, therapists, and doctors provides personalized one-on-one sessions to support you on your recovery journey. Each professional offers tailored assessments and interventions that address both physical and mental health needs. These sessions empower clients to navigate their challenges, set achievable goals, and develop effective strategies for lasting change, creating a supportive environment for healing and growth.",
            offerings: [
              { title: "Younis Abu Hashish", subSessions: [] },
              { title: "Ammar", subSessions: [] },
              { title: "Osama Al-Zahouri", subSessions: [] },
              { title: "Sami Al-Baziri", subSessions: [] },
              { title: "Dr.Huzeyfe Barham", subSessions: [] },
              { title: "Dr.Tariq Hajawi", subSessions: [] },
              { title: "Mohammed Al-Dawaki", subSessions: [] },
              { title: "Issam Al-Zaitouni", subSessions: [] },
            ],
            programComponents: [],
          },
        ],
        description: "",
        offerings: [],
        keyDeliverables: [],
        callToAction: "",
      },
    },
    {
      name: t("titles.afterCareProgram"),
      desc: "The Aftercare service  focuses on supporting individuals after completing the primary phase of treatment to ensure continued recovery and long-term stability. This service provides personalized follow-up sessions, personal development plans, and support resources to help individuals navigate daily challenges. It aims to strengthen life skills, build a solid support network, and motivate individuals to achieve their personal and professional goals. Aftercare serves as a bridge between primary treatment and daily life, helping to reduce the risk of relapse and promoting a healthy, stable lifestyle.",
      image: afterCareImage,
      id: "3",
      path: "program",
      title: null,
      link: "https://wa.me/+905551675716",
      programData: {
        subPrograms: [
          {
            id: "4",
            path: "program-details",
            name: "Aftercare Program",
            description:
              "In our commitment to supporting sustainable recovery and building a better life, we present tailored aftercare and follow-up programs specifically designed to meet the needs of individuals in recovery and their families.",
            offerings: [
              {
                title:
                  "8 Therapy Sessions: 4 sessions for the person in recovery and 4 sessions for family members",
                subSessions: [],
              },
              {
                title:
                  "4 Life Coaching Sessions: to enhance support and guidance",
                subSessions: [],
              },
              {
                title:
                  "8 Fitness Training Sessions: to maintain physical fitness and activity",
                subSessions: [],
              },
              {
                title:
                  "Appointment Coordination and Continuous Follow-Up: to ensure adherence to the program",
                subSessions: [],
              },
            ],
            duration: "One month",
          },
          {
            id: "5",
            path: "program-details",
            name: "Aftercare 2 Follow-up Program",
            description:
              "In our commitment to supporting sustainable recovery and building a better life, we present tailored aftercare and follow-up programs specifically designed to meet the needs of individuals in recovery and their families.",
            offerings: [
              {
                title:
                  "8 Therapy Sessions: 4 sessions for the person in recovery and 4 sessions for family members",
                subSessions: [],
              },
              {
                title: "2 Sessions with Coach Saad Al-Mahmoud",
                subSessions: [],
              },
              {
                title: "2 Life Coaching Sessions",
                subSessions: [],
              },
              {
                title:
                  "8 Fitness Training Sessions: for physical fitness follow-up",
                subSessions: [],
              },
            ],
            duration: "",
          },
          {
            id: "6",
            path: "program-details",
            name: "100 Days Stability Program",
            description:
              "In our commitment to supporting sustainable recovery and building a better life, we present tailored aftercare and follow-up programs specifically designed to meet the needs of individuals in recovery and their families.",
            offerings: [
              {
                title:
                  "6 Sessions with Saad Al-Mahmoud during the program period",
                subSessions: [],
              },
              {
                title:
                  "24 Sessions divided between psychotherapy and recovery coaching for continuous support, as follows : ",
                subSessions: [
                  "6 sessions with Coach Saad Al-Mahmoud",
                  "6 sessions with Therapist Younis Abu Hashish",
                  "6 sessions with Coach Ammar",
                  "6 sessions with Coach Mohammed Al-Dawaki",
                ],
              },
            ],
            duration: "100 days",
          },

          {
            id: "7",
            path: "program-details",
            name: "Youth Recovery Community",
            description:
              "Join us now in the Youth Recovery Community! You can participate in our weekly sessions in every city and quarterly gatherings at our main headquarters in Turkey, along with annual trips with the entire team and the recovery community. You can also volunteer with us in the recovery community.",
            offerings: [],
            duration: "",
          },
        ],
        description: "",
        offerings: [],
        keyDeliverables: [],
        callToAction: "",
      },
    },
    {
      name: t("titles.detox"),
      desc: "The Detox Program  is designed to help individuals safely and effectively eliminate toxins and harmful substances from their bodies. The program focuses on providing medical and therapeutic support throughout the detoxification process, ensuring a comfortable and secure environment. The program helps individuals adjust to the physical and emotional changes that come with detox, serving as a crucial step to start the recovery journey on the right path.",
      id: "4",
      path: "program",
      image: detoxImage5,
      title: "",
      link: "https://wa.me/+905551675716",
      programData: {
        subPrograms: [],
        description:
          "The detox process is a critical first step in addiction treatment, designed to cleanse the body from toxic substances safely and systematically. It begins with a thorough assessment of the patient's physical and mental health to establish the most suitable treatment plan. Supportive treatments are provided to ease withdrawal symptoms, with continuous monitoring to ensure the patient’s health remains stable throughout the process.",
        keyDeliverables: [
          {
            title: "Home Detox",
            details:
              "This service is tailored for patients who wish to undergo detox safely in the comfort of their own home. A specialized medical team provides 24/7 support, continuously monitoring health and offering necessary medical care. This option is ideal for those who prefer privacy and a more relaxed environment compared to a hospital setting.",
          },
          {
            title: "Outpatient Detox at the Clinic",
            details:
              "This service includes daily or scheduled visits to the clinic, where patients receive regular support and treatment without needing hospital admission. Medical professionals monitor the patient’s health consistently to ensure they receive the appropriate care.",
          },
          {
            title: "Inpatient Detox at the Hospital",
            details:
              "For patients who need constant medical supervision, the hospital provides round-the-clock monitoring, with access to advanced equipment and facilities. This option is recommended for patients with complex health conditions that require immediate intervention.",
          },
        ],
        callToAction: "",
      },
    },
  ];
};

export const courses = [
  {
    name: "Memories of an Addict",
    img: courseImage1,
    path: "courses",
    id: "1",
    description:
      "Let me open my heart to tell you my story. Allow me to take you back in time and recount ten moments I will never forget with that cursed addiction, detailing the transitional stages on my path to recovery and explaining what needs to be done at each stage so that this curse may be broken forever. May God grant you some comfort and hope in the sincerity of my words, and perhaps you will find in my way a way out of your addiction, leading you to true and lasting happiness.",
    courseURL:
      "https://drive.google.com/file/d/1tCGQe5TOTyxgbE6xL1ntgxv8n8cmILHQ/view?usp=sharing",
  },
  {
    name: "How to Overcome Psychological Issues After Addiction?",
    img: courseImage2,
    path: "courses",
    id: "2",
    description:
      "In this course, I share my personal journey through psychological challenges following addiction, including social anxiety, panic attacks, anxiety, depression, anger management, delusions, low self-esteem, shame, guilt, and frustration. I discuss how I’ve addressed these issues and continue to work on my recovery.",
    courseURL:
      "https://drive.google.com/file/d/1A0VY4gZDt9fU2SlLPJKd2olxmN3dT_Sr/view?usp=sharing",
  },
  {
    name: "How to Help a Loved One Overcome Addiction",
    img: courseImage3,
    path: "courses",
    id: "3",
    description:
      "This course summarizes my practical experience with addicts, especially those close to me. It outlines a practical plan to encourage change and treatment, addresses how to handle relapses during recovery, and identifies drug types and signs of substance use issues.",
    courseURL:
      "https://drive.google.com/file/d/1I7F9pV1A_ZYO6XTcuSas823VNYbfiDIQ/view?usp=sharing",
  },
  {
    name: "Ready fitness & Nutrition plans",
    img: courseImage4,
    path: "courses",
    id: "4",
    description:
      "Ready Fitness & Nutrition Plans provide personalized workout and meal plans tailored to individual goals. Designed to promote sustainable habits, these plans enhance overall well-being and support weight loss, muscle building, and improved health. Get the guidance you need for lasting success!",
  },
  {
    name: "A Life Free from Pornography",
    img: courseImage5,
    path: "courses",
    id: "5",
    description:
      "This course is your complete guide to overcoming pornography and compulsive sexual behaviors. It covers three key areas: the relationship between physical energy and pornography, the role of the mind and unconscious in addiction, and spiritual enlightenment. Through this journey, you will gain insights to help you understand and overcome these challenges, ultimately guiding you back to your natural state .",
    courseURL:
      "https://youtube.com/playlist?list=PLxV5FDnhxI3JYbB6cNvHYR4iMfURtgu1D&si=z5esdfNJi9Wnuyhc",
  },
  {
    name: "My Recovery Journal",
    img: courseImage6,
    path: "courses",
    id: "6",
    description:
      "This course is a compilation of spontaneous short video diaries I recorded throughout my journey. These videos capture my thoughts and experiences, offering insights that may help and entertain you as you navigate your own recovery journey. They provide relatable moments and reflections that aim to support and inspire you during this challenging time.",
    courseURL:
      "https://youtube.com/playlist?list=PLxV5FDnhxI3L6vpzszytMRZtI0Okahb9J&si=vnlcNZ4Pts_9XXaH",
  },
];

export const commentsData = [
  {
    top: "3%",
    left: "10%",
    image: user1,
    size: 60,
    content:
      "استفدت الحمدلله وتعلمت أن لكل انسان طاقة ويستطيع توفيرها في ماهو مفيد",
  },
  {
    top: "10%",
    left: "50%",
    image: user2,
    size: 60,
    content: "نعم وبشدة، غير لي حياتي للأفضل إن شاء الله",
  },
  {
    top: "50%",
    left: "10%",
    image: user3,
    size: 58,
    content: "نعم ، معلومات جديدة كثيرة مهمة ومفيدة",
  },
  {
    top: "30%",
    left: "60%",
    image: user4,
    size: 70,
    content: "الفريق مميز ومن افضل ما يكون",
  },
  {
    top: "80%",
    left: "80%",
    image: user5,
    size: 70,
    content: "الاهتمام والتطرف الى كل الجوانب المتعلقه بالادمان",
  },
  {
    top: "0%",
    left: "20%",
    image: user6,
    size: 64,
    content: "الاهتمام والنصح والإرشاد من قِبل المدربين",
  },
  {
    top: "10%",
    left: "90%",
    image: user7,
    size: 68,
    content: "المعلومات القيمة والجديدة بالنسبة لي",
  },
  {
    top: "15%",
    left: "5%",
    image: user8,
    size: 70,
    content:
      "المعنويه العاليه وتجديد ثقتي فنفسي وتغير تفكيري حتى اسلويي الى الافضل بسبب الاسلوب الراقي الي بتعاملو فيه تحياتي كوتش سعد اسامه والدوعكي ..",
  },
  {
    top: "10%",
    left: "70%",
    image: user9,
    size: 70,
    content: "زرع فيني الأمل والثقة بالله",
  },
  {
    top: "60%",
    left: "50%",
    image: user10,
    size: 58,
    content: "اثره ايجابي المزاج جيد وهادئ وبدأت الاحظ التركيز والثقه",
  },
  {
    top: "65%",
    left: "30%",
    image: user11,
    size: 74,
    content:
      "اثر بشكل ايجابي كيير والاهل وزوجتي بلشو يلاحظو تفكيري واسلوبي تغير بناحيه ايجابيه وانا سعيد بسبب هاشي ..بس عندي تخويت وهبل من شخصيتي الي لسا بستكشف فيها نفسي 🥵😂",
  },
  {
    top: "20%",
    left: "20%",
    image: user12,
    size: 70,
    content:
      "فعليا ممتازه من ناحية التجربة ومحاكاة الشعور الفعلي لمن يحاول التخلص من الادمان",
  },
  {
    top: "20%",
    left: "30%",
    image: user13,
    size: 64,
    content: "جميل واستطيع القول أنها تجربة ناجحة ان شاءالله",
  },
  { top: "40%", left: "90%", image: user1, size: 58, content: "محترف" },
  {
    top: "80%",
    left: "5%",
    image: user14,
    size: 72,
    content: "الله يعطيكم العافية ما قصرتوا معنا والله",
  },
  {
    top: "65%",
    left: "15%",
    image: user15,
    size: 64,
    content: "الله يوفقكم ويكتب لكم الاجر يا رب ولدي اموره طيبة جدا",
  },
  {
    top: "20%",
    left: "40%",
    image: user16,
    size: 60,
    content: "رحلة مميزة وفريدة من نوعها",
  },
  {
    top: "55%",
    left: "75%",
    image: user17,
    size: 54,
    content:
      "كانت صعبة فالبداية و لكن ابدت نتائج رائعة في نهاية العلاج : منها تغيير النمط الفكري و تغيير الاهتمامات لأمور إيجابية",
  },
  {
    top: "30%",
    left: "80%",
    image: user18,
    size: 76,
    content: "البرنامج غير تقليدي و متنوع وفيه الكثير من الانضباط",
  },
];

export const commentsEliteWellbeingData = [
  {
    image: user1,
    size: 70,
    top: "10%",
    left: "10%",
    content:
      "اكبر شيء قدمتوه لابني انه خليتو عنده شغف كبير بالرياضة وهالشي اثر على كل شي بشكل ايجابي الصراحة ما توقعت الرياضة لهدرجة بتأثر",
  },
  {
    image: user2,
    size: 70,
    top: "20%",
    left: "35%",
    content:
      "بالنسبة لابني كان الانستجرام عنده في بنات قبل اكثر من شهر وربع عاديين ب ما نفضلهن, الحين اختفوا كلهم , وصارت صفحته واهتماماته وكل الفيديوهات اللي يتابعها حق كمال الاجسام",
  },
  {
    image: user3,
    size: 62,
    top: "40%",
    left: "80%",
    content:
      "الحين ولدي اموره طيبة , حاليا اهتمامه كشخة وطلعات وجي بس بشكل محدود , يقول احس اني نظيف وغير ومستمتع بدون خوف فالحمد لله كثيرا",
  },
  {
    image: user4,
    size: 50,
    top: "15%",
    left: "80%",
    content:
      "الحمدالله استجاب ابني كثيرا للعلاج لأنه كان برنامجا متكاملا من لياقة بدنية إلى غذاء صحي و طبعا اهم شي الجلسات النفسية ... وطريقة البرنامج مختلفة عن البرامج التقليدية ، أن يسكن ابني بسكن لحاله و معاه التلفون و قدرة الحركة بحرية برفقة مرافق لعبت دور كبير في تحفيزه لتلقي العلاج و الثقة بالبرنامج …",
  },
  {
    image: user5,
    size: 78,
    top: "30%",
    left: "25%",
    content:
      "خدمة المرافقين والمعالجين والمدربين رائعة و متوفرين للتواصل على مدار اليوم كامل تقريبا لاجوبة اي من استفسارات الاهل",
  },
  {
    image: user6,
    size: 64,
    top: "70%",
    left: "15%",
    content:
      "الفريق العلاجي يتواصل مع الاهل بشكل مباشر و بشكل يومي تقريبا في بدايه العلاج حتى استقرار الحالة",
  },
  {
    image: user7,
    size: 74,
    top: "80%",
    left: "60%",
    content:
      "الحمدلله اولا و اخيرا ، لكم الفضل بعد الله سبحانه و تعالي في هداية ابننا",
  },
  {
    image: user8,
    size: 72,
    top: "65%",
    left: "75%",
    content:
      "سرعة التواصل وتحديث اول باول عن الحالة وخبرة في التعامل وايضاً بحكم ان معظم الحالات لديكم شباب وسنكم قريب منهم كان له دور كبير في تقبل الحالة فريقكم",
  },
  {
    image: user9,
    size: 56,
    top: "80%",
    left: "45%",
    content:
      "تم تغيير تفكير ابننا و زرع حب الرياضة لديه مما اثر على تفكيره و على روتينه اليومي بشكل ايجابي",
  },
  {
    image: user10,
    size: 80,
    top: "10%",
    left: "50%",
    content:
      "كانت قيمة البرنامج مرتفعة بالنسبة لنا ، و لكن نقدر ما تم تقديمه لابننا بشكل دقيق و مناسب لحالته",
  },
];

export const testimonials = [
  {
    title: "الحمدالله استجاب ابني كثيرا للعلاج",
    desc: "الحمدالله استجاب ابني كثيرا للعلاج لأنه كان برنامجا متكاملا من لياقة بدنية إلى غذاء صحي و طبعا اهم شي الجلسات النفسية ... وطريقة البرنامج مختلفة عن البرامج التقليدية ، أن يسكن ابني بسكن لحاله و معاه التلفون و قدرة الحركة بحرية برفقة مرافق لعبت دور كبير في تحفيزه لتلقي العلاج و الثقة بالبرنامج ",
  },
  {
    title: "بعدها بشهر بدأ تغيير بسيط",
    desc: "كانت صعبة جدا فالبدايه للفريق العلاجي و للاهل بشكل اخص و ذلك بسبب مقاومه ابننا للكثير من الخطة العلاجية ، بعدها بشهر بدأ تغيير بسيط و بعدها بشهرين تم الملاحظة على بدايه التغيير في السلوك و الاهتمامات والتوجه للرياضة بشكل مفرط مما ادى الى تنظيم الاكل و النوم و الذي ساعد بشكل كبير فالتشافي .",
  },
  {
    title: "آمنت ليتعافى ابني من الإدمان",
    desc: "نحن نضع المال من أجل شراء سيارة أو تحسين منزل أو سفر ... ابنك او بنتك أولى بهذا المبلغ ... آمنت ليتعافى ابني من الإدمان علي أن أنفق مبلغا وقدره ، الأهل فيهم يتسلفوا جزء من المبلغ و جزء توفير و الله ما راح تندمون على هالشي ...البرنامج يحاكي عقول الشباب و يتماشى مع شخصياتهم ، البرنامج مقنع جدا بالنسبة للشباب و يعطيهم أمل كبير في التعافي",
  },
  {
    title: "خضع لعلاج طبي لمدة شهر كامل في إحدى المصحات",
    desc: "عانى ابننا من الادمان لمدة تقريبا ٤ سنوات و في بدايه عمر البلوغ ، و كان من الصعب جدا السيطرة على وضعه خلال هذه السنوات لانه كان محاط بصحبة سيئة جداً ، خضع لعلاج طبي لمدة شهر كامل في إحدى المصحات ، و لكن كان علاجي جسدي غير سلوكي ، مما أدى لانتكاسه مباشره .",
  },
];

export const phases = [
  {
    id: 1,
    title: "Detox",
    description: [
      "Conduct comprehensive laboratory tests and detoxification procedures.",
      "Ensure all organs are functioning properly, and there are no deficiencies in any vitamins or minerals in the body. Confirm the participant is free from any diseases.",
      "This phase is done in collaboration with the Shadow Clinic and the French Hospital in Turkey under the supervision of Dr. Hudhaifa Barham.",
    ],
    duration: "2 to 4 weeks",
    image: phase1,
  },
  {
    id: 2,
    title: "Brain Cell Renewal and Comprehensive Program by Restart",
    description: [
      "Identify the reasons behind the participant's addiction and address them at their roots, providing the necessary treatment through a system of various non-pharmacological therapies.",
      "Brain cell renewal and a comprehensive approach program that addresses the body, mind, and spirit. The duration is 3 to 5 months, with intensive and comprehensive follow-up with the family. It also includes recreational trips and activities to develop hobbies.",
    ],
    duration: " 3 to 6 months",
    image: phase2,
  },
  {
    id: 3,
    title: "Community Integration Aftercare",
    description: [
      "Help participants reach a level of maturity in social, psychological, and spiritual aspects. Assist participants in setting life goals with life coaches.",
      "Prepare participants for reintegration into society, providing support and follow-up through the Restart support group.",
      "They can move to live in one of the recovery houses in the city where they reside, allowing them to attend university and work in a safe place. There will be intensive and comprehensive follow-up with the family.",
    ],
    duration: "Sustainably",
    image: phase3,
  },
];

export const blogs = [
  {
    path: "/blog",
    id: "1",
    image: blogImage1,
    title: "What Drives Drug Addicts to Relapse?",
    desc: "Relapse is often seen as a natural part of the recovery process from addiction, but it can pose significant challenges for those trying to break free from substance abuse. Understanding the reasons behind relapse can help in developing strategies for maintaining sobriety. Here are some key factors that may lead to relapse among addicts",
    points: `
    <h3>1. Psychological Pressure and Stress</h3>
    <p>One of the primary reasons for relapse is the psychological pressure and stress that individuals face. When confronted with life challenges or ongoing stress, many addicts may feel incapable of coping without turning to drugs.</p>
    <strong>Causes of Stress:</strong>
    <p>Common stressors include work-related pressures, family conflicts, or financial troubles. These issues can create a sense of helplessness in the addict, making them more vulnerable to relapse.</p>
    <strong>Coping Strategies:</strong>
    <p>It’s essential for recovering addicts to adopt healthy ways to manage stress, such as engaging in meditation, exercising, or discussing their feelings with supportive friends or family.</p>

    <h3>2. Lack of Family and Social Support</h3>
    <p>The presence of strong social and familial support is crucial for successful recovery. When addicts lack this support, they may experience feelings of isolation, which can increase the likelihood of returning to substance use.</p>
    <strong>Feelings of Loneliness:</strong>
    <p>The absence of a supportive network can leave an addict feeling alone, leading them to revert to old habits as a means of escape.</p>
    <strong>Importance of Support:</strong>
    <p>Encouragement from family and friends can play a significant role in recovery by fostering a sense of belonging and reducing emotional strain.</p>

    <h3>3. Exposure to Triggers</h3>
    <p>Triggers are another critical factor that can lead to relapse. These include people or places associated with the addict’s past substance use.</p>
    <strong>Familiar Places:</strong>
    <p>Visiting locations tied to previous drug use, such as old friends’ homes or bars, can evoke memories and cravings, increasing the temptation to use drugs again.</p>
    <strong>Triggering Individuals:</strong>
    <p>Friends or acquaintances who are still using drugs can also serve as powerful triggers. It’s advisable for recovering addicts to steer clear of such environments to avoid temptation.</p>

    <h3>Strategies to Prevent Relapse</h3>
    <p>Despite the challenges, there are effective methods to help reduce the risk of relapse:</p>

    <h4>1. Continuous Support</h4>
    <p>Having a robust support system from family and friends is vital in minimizing the chances of relapse.</p>
    <strong>Joining Support Groups:</strong>
    <p>Participating in support groups like Alcoholics Anonymous or Narcotics Anonymous provides a safe space to share feelings and experiences.</p>
    <strong>Encouragement from Family:</strong>
    <p>Families should encourage their loved ones to express their feelings and provide emotional support.</p>

    <h4>2. Healthy Stress Management Techniques</h4>
    <p>Individuals need to learn how to cope with stress and pressures without resorting to drugs.</p>
    <strong>Alternative Techniques:</strong>
    <p>Practices such as yoga, meditation, and deep breathing can be beneficial in managing stress.</p>
    <strong>Learning New Skills:</strong>
    <p>Picking up new hobbies, like cooking or painting, can help distract the mind and reduce cravings for drugs.</p>

    <h4>3. Learning from Past Relapses</h4>
    <p>If a person has experienced a relapse, it is crucial to reflect on what happened and learn from it.</p>
    <strong>Analyzing Triggers:</strong>
    <p>Understanding the factors that led to a previous relapse can help in creating new coping strategies.</p>
    <strong>Changing Habits:</strong>
    <p>If certain behaviors contribute to relapse, it’s important to work on modifying those habits or finding healthier alternatives.</p>

    <h3>Relapse Is Not the End</h3>
    <p>Recovery is a journey filled with ups and downs. Relapse may occur, but what truly matters is the ability to recover and continue moving forward.</p>
    <strong>Reevaluation:</strong>
    <p>After a relapse, it’s important for the individual to reassess their situation and set new goals for recovery.</p>
    <strong>Finding Hope:</strong>
    <p>It’s essential for recovering addicts to remember that they are not alone. Many have faced similar challenges and successfully overcome them.</p>

    <h3>Conclusion</h3>
    <p>Relapse is a common part of the recovery process, but by recognizing the underlying causes and implementing effective support and coping strategies, the likelihood of relapse can be reduced. Both the recovering individual and their family must understand that recovery is an ongoing process that requires patience and perseverance. Ultimately, relapse can serve as a new beginning on the path to complete recovery.</p>
  `,
  },
  {
    path: "/blog",
    id: "2",
    image: blogImage2,
    title:
      "How Can Parental Support Contribute to Increased Relapse Among Drug Addicts?",
    desc: "Addiction is one of the most significant challenges individuals and their families face in today’s world. While many understand that parental support can have positive effects on recovering addicts, it is less known that this support, when misguided or inappropriate, can actually lead to increased relapse rates. In this article, we will explore how parental support can exacerbate addiction issues and suggest appropriate ways to address this matter.",
    points: `
    <h3>Understanding Addiction</h3>
    <p>Before discussing the impact of parental support, it’s crucial to comprehend the nature of addiction...</p>

    <h3>Factors Contributing to Increased Relapse</h3>
    <h4>Excessive Leniency</h4>
    <p>One of the primary reasons parental support can negatively impact addiction recovery is excessive leniency...</p>
    <strong class="psychological-impact">Psychological Impact:</strong>
    <p>This leniency can reinforce the addict’s belief that they are safe from consequences...</p>

    <h4>Financial Support for Addiction</h4>
    <p>Another way parental support can inadvertently contribute to relapse is through financial assistance...</p>
    <strong class="solution">Solution:</strong>
    <p>Parents should exercise caution in providing financial support...</p>

    <h4>Justifying Behaviors</h4>
    <p>Justifying an addict’s behaviors by claiming they are going through tough times...</p>
    <strong class="challenge">Challenge:</strong>
    <p>Parents who justify the addict’s behaviors may overlook the urgent need for confrontation...</p>

    <h4>Neglecting Professional Treatment</h4>
    <p>Sometimes, parental support for addicts can be associated with neglecting the need for professional treatment...</p>
    <strong class="importance">Importance of Treatment:</strong>
    <p>Therefore, it is essential for parents to seek effective treatment options...</p>

    <h3>How Can Parents Support Addicts Effectively?</h3>
    <h4>Establish Clear Boundaries</h4>
    <p>Parents should set clear boundaries regarding the addict's behaviors...</p>
    <strong class="effective-communication">Effective Communication:</strong>
    <p>Open dialogue between parents and the addict about acceptable and unacceptable behaviors...</p>

    <h4>Provide Professional Treatment</h4>
    <p>Parents should actively seek available treatment options...</p>
    <strong class="encouraging-positivity">Encouraging Positivity:</strong>
    <p>Parents can also encourage the addict to engage in activities that promote recovery...</p>

    <h4>Commit to Realism</h4>
    <p>Parents need to be realistic about the nature of addiction...</p>
    <strong class="education-awareness">Education and Awareness:</strong>
    <p>Parents can enhance their knowledge about addiction through reading or attending workshops...</p>

    <h3>Conclusion</h3>
    <p>Ultimately, parents must recognize that their support for addicts can significantly impact the recovery journey...</p>
  `,
  },
  {
    path: "/blog",
    id: "3",
    image: blogImage3,
    points: `
    <h3>Expected Duration of Recovery from Addiction</h3>
    <h4>General Recovery Timeline</h4>
    <p>Typically, recovery can take anywhere from 6 months to a year to achieve a stable state...</p>

    <h4>Stabilization Phase</h4>
    <p>After the initial treatment period, individuals begin to reach a state of stability...</p>

    <h3>Factors Affecting Recovery Duration</h3>
    <h4>Type of Addiction</h4>
    <strong class="highlight">Addiction to Strong Substances:</strong>
    <p>Addictions to potent substances like heroin or cocaine often require a longer recovery process...</p>
    <strong class="highlight">Addiction to Alcohol or Milder Drugs:</strong>
    <p>While recovery from these substances may take less time, it does not mean the journey will be easy...</p>

    <h4>Familial and Psychological Support</h4>
    <strong class="highlight">Supportive Environment:</strong>
    <p>This provides a sense of safety and the emotional support necessary for recovery...</p>
    <strong class="highlight">Encouragement of Positivity:</strong>
    <p>Family and friends can inspire the recovering addict to remain optimistic...</p>

    <h4>Professional Treatment and Assistance</h4>
    <strong class="highlight">Behavioral Therapy:</strong>
    <p>This is an effective approach that helps addicts overcome negative habits...</p>
    <strong class="highlight">Medication:</strong>
    <p>Certain medications can help reduce cravings and alleviate withdrawal symptoms...</p>

    <h3>Why Is Recovery an Ongoing Journey?</h3>
    <h4>Continuous Challenges</h4>
    <p>Recovery is not just a goal to be achieved and then forgotten; it is an ongoing process...</p>

    <h4>Importance of Commitment</h4>
    <p>It’s essential for individuals to understand that relapses may occur, but they do not signify the end...</p>
    <strong class="highlight">Learning from Experiences:</strong>
    <p>Understanding the reasons behind the relapse and working to avoid similar triggers in the future...</p>
    <strong class="highlight">Seeking Help:</strong>
    <p>Being open to seeking support when needed, whether from friends or professionals...</p>

    <h3>Strategies to Enhance Recovery</h3>
    <h4>Strengthening Social Networks</h4>
    <p>Maintaining healthy, supportive relationships can significantly enhance recovery...</p>
    <strong class="highlight">Join Support Groups:</strong>
    <p>These groups provide a safe space for connecting with others facing similar challenges...</p>

    <h4>Developing Personal Skills</h4>
    <p>Learning new skills can boost self-confidence and enhance an individual’s ability to face challenges...</p>
    <strong class="highlight">Engage in New Activities:</strong>
    <p>Pursuing sports or arts can help improve mental well-being...</p>

    <h3>Conclusion</h3>
    <p>In summary, the duration of recovery from drug addiction varies from person to person and depends on several factors...</p>
  `,
    title:
      "How Long Does Recovery from Addiction Take? Factors Affecting the Journey to Sobriety",
    desc: "Drug addiction is a complex condition that impacts various aspects of an individual’s life. Recovery from addiction is often a long and complicated process. Many people wonder how long this journey may take, but the answer isn’t straightforward. Recovery is a personal journey influenced by several factors. In this article, we will discuss the expected duration of recovery, the factors that affect it, and the importance of psychological and familial support in speeding up this process",
  },
  {
    path: "/blog",
    id: "4",
    image: blogImage4,
    points: `
  <h3>Expected Duration of Recovery from Addiction</h3>

  <h4>General Recovery Timeline</h4>
  <p>Typically, recovery can take anywhere from 6 months to a year to achieve a stable state...</p>

  <h4>Stabilization Phase</h4>
  <p>After the initial treatment period, individuals begin to reach a state of stability...</p>

  <h3>Factors Affecting Recovery Duration</h3>

  <h4>Type of Addiction</h4>
  <strong class="highlight">Addiction to Strong Substances:</strong>
  <p>Addictions to potent substances like heroin or cocaine often require a longer recovery process...</p>

  <strong class="highlight">Addiction to Alcohol or Milder Drugs:</strong>
  <p>While recovery from these substances may take less time, it does not mean the journey will be easy...</p>

  <h4>Familial and Psychological Support</h4>

  <strong class="highlight">Supportive Environment:</strong>
  <p>This provides a sense of safety and the emotional support necessary for recovery...</p>

  <strong class="highlight">Encouragement of Positivity:</strong>
  <p>Family and friends can inspire the recovering addict to remain optimistic...</p>

  <h4>Professional Treatment and Assistance</h4>

  <strong class="highlight">Behavioral Therapy:</strong>
  <p>This is an effective approach that helps addicts overcome negative habits...</p>

  <strong class="highlight">Medication:</strong>
  <p>Certain medications can help reduce cravings and alleviate withdrawal symptoms...</p>

  <h3>Why Is Recovery an Ongoing Journey?</h3>

  <h4>Continuous Challenges</h4>
  <p>Recovery is not just a goal to be achieved and then forgotten; it is an ongoing process...</p>

  <h4>Importance of Commitment</h4>
  <p>It’s essential for individuals to understand that relapses may occur, but they do not signify the end...</p>

  <strong class="highlight">Learning from Experiences:</strong>
  <p>Understanding the reasons behind the relapse and working to avoid similar triggers in the future...</p>

  <strong class="highlight">Seeking Help:</strong>
  <p>Being open to seeking support when needed, whether from friends or professionals...</p>

  <h3>Strategies to Enhance Recovery</h3>

  <h4>Strengthening Social Networks</h4>
  <p>Maintaining healthy, supportive relationships can significantly enhance recovery...</p>

  <strong class="highlight">Join Support Groups:</strong>
  <p>These groups provide a safe space for connecting with others facing similar challenges...</p>

  <h4>Developing Personal Skills</h4>
  <p>Learning new skills can boost self-confidence and enhance an individual’s ability to face challenges...</p>

  <strong class="highlight">Engage in New Activities:</strong>
  <p>Pursuing sports or arts can help improve mental well-being...</p>

  <h3>Conclusion</h3>
  <p>In summary, the duration of recovery from drug addiction varies from person to person and depends on several factors...</p>
`,
    title:
      "How to Maintain Your Mental Health While Supporting Your Addicted Child",
    desc: "Dealing with a child's addiction is a painful and challenging experience that can profoundly affect your mental health. Amidst these challenges, it is crucial to prioritize your mental well-being to ensure you can provide effective and ongoing support. While this task may seem overwhelming, you can achieve the necessary balance between supporting your child and caring for yourself by following thoughtful strategies. In this article, we outline important tips for maintaining your mental health during this difficult time",
  },
  {
    path: "/blog",
    id: "5",
    image: blogImage5,
    points: `
  <h3>The Importance of Setting Boundaries</h3>
  <p>Setting boundaries promotes accountability and personal awareness, helping individuals realize the impact of their behavior on others. Addicts may feel anxious or stressed about losing support, but healthy boundaries are essential in fostering recovery.</p>

  <h3>Tips for Effectively Setting Boundaries with an Addict</h3>

  <h4>Define Responsibilities</h4>
  <p><strong>Encourage Personal Accountability:</strong> The addict must understand that they are responsible for their actions and behaviors. Ensure that negative behaviors, such as lying or stealing, are not tolerated. This responsibility fosters self-awareness and makes them more cognizant of the consequences of their actions.</p>
  <p><strong>Focus on Daily Responsibilities:</strong> Encourage the addict to take on ordinary responsibilities like work or studies. This can help them regain self-confidence and improve their sense of achievement.</p>

  <h4>Reject Negative Behaviors</h4>
  <p><strong>Identify Unacceptable Behaviors:</strong> Be clear about what constitutes negative behavior, such as aggression or dishonesty. Avoid justifying these actions under the guise of addiction, as this may worsen the issue rather than resolve it.</p>
  <p><strong>Use Clear Statements:</strong> When communicating with the addict, use clear and direct statements that express your feelings, such as, "I will not accept lying in this household."</p>

  <h4>Condition Support on Treatment</h4>
  <p><strong>Emphasize the Importance of Treatment:</strong> Make it clear that family support will not continue if the addict does not show a genuine effort toward recovery. This is a necessary step in the recovery process, demonstrating that the addict is responsible for taking positive steps.</p>
  <p><strong>Develop a Treatment Plan:</strong> Collaborate with the addict to create a clear treatment plan that includes appointments, activities, and goals. This plan enhances commitment and clarifies expectations.</p>

  <h4>Prioritize Your Mental Health</h4>
  <p><strong>Set Aside Time for Yourself:</strong> Avoid immersing yourself in the addict’s problems; it's vital to retain time for yourself and focus on your mental and physical health. Self-care enhances your ability to provide effective support.</p>
  <p><strong>Consult Professionals:</strong> Consider seeking psychological support from professionals experienced in dealing with addiction issues. They can offer effective strategies for setting boundaries and providing support.</p>

  <h4>Clearly Define Expectations</h4>
  <p><strong>Be Open and Honest:</strong> Clearly articulate your expectations of the addict. Specify the commitments they should make, such as attending therapy sessions or participating in support groups.</p>
  <p><strong>Clarify Recovery Goals:</strong> Outline the recovery goals that need to be achieved, such as abstaining from drugs for a specific period. Clarity prevents misunderstandings and helps create a clear path.</p>

  <h4>Monitor Financial Support</h4>
  <p><strong>Protect Financial Resources:</strong> It is essential not to finance the addict's negative habits. Be cautious with any financial support, as it may be used to fuel their addiction rather than improve their health.</p>
  <p><strong>Offer Non-Financial Support:</strong> Instead of financial assistance, provide help in other life areas, such as supplying food or assisting with housing.</p>

  <h3>Benefits of Setting Boundaries with an Addict</h3>
  <p>Establishing boundaries helps organize the family situation, encourages the addict to take personal responsibility, and creates a more stable environment for everyone. Here are some key benefits:</p>
  <ul>
    <li><strong>Promotes Accountability:</strong> Clear boundaries lead the addict to feel more responsible for their actions.</li>
    <li><strong>Improves Family Mental Health:</strong> Setting boundaries helps reduce stress and anxiety among family members, enhancing their mental well-being.</li>
    <li><strong>Creates a Supportive Environment:</strong> Boundaries help build a warm and supportive atmosphere while maintaining a level of firmness and clarity.</li>
  </ul>

  <h3>How to Maintain Boundaries</h3>

  <h4>Continuous Evaluation</h4>
  <p>Periodically assess your boundaries. Be prepared to adjust them based on the addict’s behavior and progress in treatment.</p>

  <h4>Keep Open Communication</h4>
  <p>Maintain open lines of communication with the addict. Encourage them to express their feelings and needs, and avoid shutting down dialogue due to negative behaviors.</p>

  <h4>Reinforce Progress</h4>
  <p>Celebrate any progress the addict makes. Positive reinforcement boosts motivation to continue their recovery journey.</p>

  <h3>Conclusion</h3>
  <p>Setting boundaries with an addict is a vital step in ensuring the health and safety of everyone involved. By defining responsibilities, rejecting negative behaviors, and securing family support, you can effectively enhance the recovery process. Remember that recovery is an ongoing journey requiring commitment and dedication from all parties involved. Ultimately, establishing boundaries contributes to creating a healthy environment that fosters positive change and ongoing support.</p>
`,
    title:
      "How to Set Boundaries with an Addict: Tips for Responsible and Effective Support",
    desc: "When a family member faces addiction, establishing personal and family boundaries becomes crucial. These boundaries are not merely rules; they are tools that help protect the mental and physical health of everyone involved, including the addict themselves. In this article, we will discuss how to effectively set these boundaries and provide tips to support the addict without exacerbating their problems.",
  },
  {
    path: "/blog",
    id: "6",
    image: blogImage6,
    points: `
        <h1>The Importance of Honesty with Children</h1>
        <p>When discussing issues of addiction, honesty is fundamental. Children are perceptive, and when they sense something is wrong, they tend to guess and arrive at conclusions that may be far from reality. Failing to inform them of the truth can lead to feelings of fear and confusion. Therefore, it is essential that children feel they can trust you and understand what is happening around them.</p>

        <h2>Choosing the Right Time</h2>
        <p>The first step in this conversation is to choose the right time. Look for a quiet moment, such as after dinner or during a short car ride, where you can talk to them without distractions. Ensure that the atmosphere is comfortable, so the children feel safe and open to discussion.</p>
        <p><strong>Tip:</strong> Avoid times when the children may be busy or stressed, such as before school or during family events. A good time to talk could be after watching a movie together or while enjoying their favorite snack, creating a relaxed atmosphere.</p>

        <h2>Use Simple Language</h2>
        <p>When starting the conversation, use simple words that are age-appropriate. For example, you could say, "Your dad is facing a health problem and needs our support, just like people with other illnesses need help." Remember that children do not understand all the complex details, so try to make things clear and easy to comprehend.</p>
        <p><strong>Clarifying the Situation:</strong> It may be helpful to use everyday life examples. You might say, "Just like we go to the doctor when we feel sick, your dad needs medical help now too."</p>

        <h2>Focus on Love</h2>
        <p>It is important that children feel that your love as a family is unconditional. You can emphasize this by saying, "Despite the challenges we face, I love you and love your dad. We are here as a family, and we will support each other through these tough times." Reinforcing love can help children feel secure in these complex circumstances.</p>

        <h2>Provide Security</h2>
        <p>Children may feel scared about the future, so you should reassure them that things will improve and that you are always there to support them. You could say, "We are working together to solve this problem, and things will get better over time. I am always here to help you." Providing security can have a significant positive impact on their mental well-being.</p>

        <h2>Listen to Their Feelings</h2>
        <p>After informing the children of what is happening, make sure to listen to their feelings. They may have fears, concerns, or even questions about what is happening. Make them feel heard, and show that you care about their feelings. You can ask, "How do you feel about what you've heard? Do you have any questions?" This fosters trust between you and creates an open communication environment.</p>

        <h2>Provide Appropriate Information</h2>
        <p>It may be necessary to give the children information about addiction and its effects. Be sure to provide them with accurate information in a way that is suitable for their ages. You can explain how their dad is receiving help now and how treatment can aid in recovery.</p>

        <h2>Emphasize the Importance of Family Support</h2>
        <p>Addiction can significantly impact the entire family, so it is essential that children understand that each family member plays a vital role in supporting their father. Explain how they can contribute, such as sending encouraging messages or engaging in family activities to strengthen bonds.</p>

        <h2>Teach Coping Skills</h2>
        <p>It is also crucial to teach children how to cope with their feelings regarding the situation. Good coping strategies might include practicing mindfulness, journaling, or engaging in activities they enjoy, such as drawing or sports. These activities can help them express their emotions healthily.</p>
        <p><strong>Tip:</strong> Try to allocate daily time for these activities together as a family, which strengthens bonds and makes children feel supported.</p>

        <h2>Seek External Support</h2>
        <p>Don’t hesitate to seek external support, such as support groups for families dealing with addiction. These groups can provide a safe space for expressing feelings and sharing experiences with others in similar circumstances.</p>
        <p><strong>Additional Point:</strong> You can also look for mental health professionals who can help guide the family during these times. Having a specialist can assist in providing strategies to better cope with stress and concerns.</p>

        <h2>Preparing Children for Challenges</h2>
        <p>Children may face various challenges due to the current situation. Ensure that you are ready to help them confront these challenges, which may include changes in their daily routine, feelings of anxiety, or even difficulties in relationships with friends. You need to be understanding and offer the necessary support.</p>
        <p><strong>How to Prepare Them?</strong> It’s important to discuss what to expect in the future and how to handle these challenges. Encourage children to talk to their friends about their feelings and be open to expressing themselves.</p>

        <h2>Reinforce Ongoing Family Support</h2>
        <p>By reinforcing ongoing family support, children can feel that they are not alone on this journey. You can all work together to overcome these challenges. You might say, "We are a team, and we will work together to help your dad."</p>

        <h2>Maintain Open Communication</h2>
        <p>After the initial conversation, don’t stop there. You should continue to communicate with the children regularly about how the situation is developing. Ensure that you are always available to answer their questions and provide support. You may have other difficult conversations, so make sure children feel they can talk to you at any time.</p>
        <p><strong>Continuity in Support:</strong> Make it easy for them to reach out to you to discuss their feelings. You could set special times during the week to talk with each child individually, enhancing open communication.</p>

        <h2>Conclusion</h2>
        <p>Talking to children about their father's addiction is a sensitive yet necessary process. Through honesty, love, and support, you can build a strong family that collaborates to face these challenges. Remember that open and loving communication can create a strong support system and aid in promoting the recovery process.</p>
        <p>Addiction is a disease that requires treatment, and the family can be a crucial pillar in this journey. By fostering trust and support, children can feel safe and empowered to face the challenges ahead. Most importantly, remember that every family member has an important role in the healing and recovery process.</p>
    `,
    title: "How to Tell Your Children That Their Father Is a Drug Addict",
    desc: "Facing the harsh reality that your children's father is addicted to drugs is an extremely difficult and painful experience. You may feel anxious about how to act, what words to use, or how this conversation will affect the children. However, remember that honesty and tenderness are key to building trust and support within the family. In this article, we will discuss how to handle this challenging situation in a way that fosters family communication and helps children understand what is happening.",
  },
  {
    path: "/blog",
    id: "7",
    image: blogImage7,
    points: `
        <h1>The Importance of Understanding Relapse</h1>
        <p>Recognizing that relapse is not the end but part of the recovery process can help reduce stress and pressure on the addict and family members. Everyone should accept that recovery is a nonlinear process and that setbacks may occur.</p>

        <h2>Tips for Effectively Coping with an Addict's Relapse</h2>
        <h3>Stay Calm and Accept the Relapse with Composure</h3>
        <p><strong>Accept the Relapse as Part of the Journey:</strong> Remember that relapse is not the end of the road. Addicts face challenges and struggles on their path to recovery. Approach the relapse with calmness and understanding.</p>
        <p><strong>Avoid Emotional Reactions:</strong> Try not to express your disappointment or frustration. Negative responses can lead the addict to feel guilty, complicating the situation further.</p>

        <h3>Be Supportive, Not Critical</h3>
        <p><strong>Listen Attentively:</strong> Instead of focusing on the relapse as a failure, ask the addict about the reasons that led to it. Make an effort to be a good listener and allow them to express their feelings.</p>
        <p><strong>Encourage Understanding:</strong> Discuss with the addict the mistakes made and how to avoid them in the future. Criticism and reprimands won't solve the problem; instead, they will increase tension. Understanding and support are key to enhancing the chances of successful recovery.</p>

        <h3>Take Immediate Steps to Adjust</h3>
        <p><strong>Seek Treatment Quickly:</strong> After a relapse, it’s crucial for the addict to return to treatment as soon as possible. Whether through returning to a treatment program or consulting with professionals, be ready to help them take the right steps back on the recovery path.</p>
        <p><strong>Provide Practical Support:</strong> Assist the addict in finding the resources they need, whether treatment centers, support groups, or mental health professionals.</p>

        <h3>Maintain Hope and Positivity</h3>
        <p><strong>View Relapse as a Learning Opportunity:</strong> Remember that a relapse is not the end; it’s a chance for learning and growth. Foster positivity in your conversations with the addict, reminding them that success is possible with patience and perseverance.</p>
        <p><strong>Provide Ongoing Family Support:</strong> Family support plays a significant role in successful recovery. Ensure that the addict feels they have a strong support network around them.</p>

        <h2>Relapse as an Opportunity for Learning and Growth</h2>
        <p>Relapse can be an opportunity to enhance psychological resilience and learn from mistakes. With family and community support, an addict can regain their strength and start anew. The key is to maintain hope and believe that every step in the recovery journey deserves support and patience.</p>

        <h2>Additional Strategies to Enhance Support</h2>
        <h3>Involve Family in Treatment</h3>
        <p>Encourage the addict to invite family members to participate in treatment, whether by attending therapy sessions or joining family support groups. This enhances the sense of solidarity and helps create a supportive environment.</p>

        <h3>Set Realistic Goals</h3>
        <p>Help the addict establish small, achievable goals after a relapse. Realistic goals enhance the sense of accomplishment and help restore confidence.</p>

        <h3>Encourage Positive Activities</h3>
        <p>Motivate the addict to engage in positive activities such as sports or hobbies. These activities can help reduce stress and promote mental well-being.</p>

        <h2>Conclusion</h2>
        <p>Relapse is a natural part of the recovery journey from addiction and should be approached with understanding and support. By calmly accepting the relapse, offering support instead of criticism, and taking immediate corrective actions, you can help the addict navigate this difficult phase. Remember that hope and positivity are keys to success in the recovery journey.</p>
    `,
    title: "How to Cope with a Relapse in Addiction: Effective Strategies",
    desc: "Relapse is a natural part of the recovery journey from addiction, with approximately 40-60% of addicts experiencing relapse at some point along their path. If someone you love faces a relapse, it’s important to understand that this is not a complete failure but rather a transitional phase that requires support and understanding. In this article, we will discuss how to support the addict during this challenging time.",
  },
  {
    path: "/blog",
    id: "8",
    image: blogImage8,
    points: `
        <h1>Signs That May Indicate Drug Addiction in Your Child</h1>

        <h2>Social Isolation and Preference for Solitude</h2>
        <p>A clear sign of addiction in children is a desire to withdraw from family and friends. If you notice that your child no longer wants to spend time with family members as they used to or prefers isolation over activities they once enjoyed, this may indicate a deeper problem. Addiction can lead individuals to feel shame or embarrassment, prompting them to distance themselves from others to avoid questions or judgments.</p>

        <h2>Sudden Changes in Weight or Appetite</h2>
        <p>One physical symptom you might observe is significant changes in your child's weight or appetite. Certain drugs can lead to noticeable loss of appetite, resulting in rapid and unexplained weight loss, while others can cause excessive appetite. If your child experiences significant weight changes without an obvious reason, this could be a warning sign.</p>

        <h2>Sleep Disturbances</h2>
        <p>Sleep disorders are another common sign among those suffering from addiction. Persistent insomnia or excessively long periods of sleep may be a result of drug use. If you notice your child has difficulty sleeping or spends most of the day sleeping without any activity, this could be concerning.</p>

        <h2>Decline in Academic or Work Performance</h2>
        <p>A sudden drop in academic or work performance, such as declining grades or neglecting responsibilities, can also be an indicator of a problem. Addiction can affect concentration and the ability to fulfill daily tasks, leading to failure in meeting obligations.</p>

        <h2>Mood Swings and Increased Irritability</h2>
        <p>Addiction is known to impact individuals' psychological and emotional states. If you notice your child experiencing rapid and unexplained mood swings—such as sudden shifts from happiness to anger or sadness—this could be a warning sign. They may also become more irritable and reactive to minor situations.</p>

        <h2>Increased Interest in Drugs or Related Activities</h2>
        <p>If your child begins to show an increased interest in drugs or activities associated with addiction, this may be a clear indicator. You might observe them spending excessive time searching for drugs or thinking about how to obtain them. They may also neglect past hobbies or social activities in favor of addiction.</p>

        <h2>Changes in Friend Circle</h2>
        <p>Another sign that may indicate a drug problem is changes in your child's circle of friends. If you notice your child spending time with new people who may be involved in unhealthy or addictive behaviors, this could be a sign of an emerging problem. Adolescents and those struggling with addiction often gravitate towards peers who share similar habits.</p>

        <h2>Lying and Secrecy</h2>
        <p>Addiction often drives individuals to lie and conceal the truth. If you notice your child becoming more secretive than usual and lying about their daily activities or whereabouts, this could be a sign that they are trying to hide addiction-related behaviors. You may also find that certain belongings go missing from home or that your child starts asking for money without a clear reason.</p>

        <h2>What to Do If You Notice These Signs</h2>

        <h3>Do Not Ignore the Signs</h3>
        <p>The first step is to acknowledge that there is a problem. Many parents may be inclined to overlook the signs, hoping they are temporary or insignificant. However, early intervention can make the difference between effectively addressing the issue or allowing it to escalate.</p>

        <h3>Open a Dialogue</h3>
        <p>The next step is to initiate an honest and open conversation with your child. Aim to be supportive and understanding rather than accusatory. The goal is to make them feel they can talk to you without fear of judgment or punishment.</p>

        <h3>Consult Professionals</h3>
        <p>It is important to seek the help of addiction treatment professionals. Doctors and counselors can provide a comprehensive assessment and develop an appropriate treatment plan.</p>

        <h3>Family Support</h3>
        <p>Addiction is not a disease that can be overcome alone. Your child will need ongoing support from the family during their recovery journey. Aim to be supportive and understanding, and participate in therapy sessions if necessary.</p>

        <h3>Look for Support Groups</h3>
        <p>Support groups, such as anonymous organizations, can be valuable resources. These groups provide a supportive environment for individuals struggling with addiction and their families.</p>

        <h3>Early Intervention</h3>
        <p>One of the most critical factors in addressing addiction is early intervention. The sooner you identify the problem, the greater the chances of complete recovery. Don’t wait for things to worsen; seek help immediately.</p>

        <h2>Conclusion</h2>
        <p>Addiction is a complex disease that requires careful monitoring and understanding from parents. If you notice any of the signs mentioned above in your child, it is essential to act quickly and seek help. There is no quick fix for addiction, but with the right support and assistance, anyone can recover and regain a normal life.</p>
        <p>Communicating with your child and collaborating with professionals can make a significant difference in their life. Prevention and early intervention are key to successfully facing this challenge.</p>
    `,
    title:
      "How to Know if Your Child is Addicted to Drugs: A Comprehensive Guide for Parents",
    desc: "Raising children comes with many challenges, and as they grow older, concerns about their physical and mental well-being often increase. One of the most sensitive issues parents can face is drug addiction. It can be difficult for parents to recognize this problem at first, as it requires careful observation and understanding to identify signs that may indicate a deeper issue.In this article, we will explore the signs that may suggest your child is struggling with drug addiction and discuss how to respond if you notice these signs.",
  },
  {
    path: "/blog",
    id: "9",
    image: blogImage9,
    points: `
        <h1>How to Help an Addict Who Refuses Treatment</h1>

        <h2>1. Understand the Reasons Patiently</h2>
        <p>When an addict refuses treatment, it may stem from various complex reasons, such as fear of treatment, denial, or a lack of recognition of their need for help. It is essential to listen to the addict without judgment. They may fear failure or the pain that might accompany treatment. By listening and showing understanding, you can help them feel less alone in their struggle.</p>

        <h3>How to Understand the Addict's Feelings:</h3>
        <ul>
            <li><strong>Choose a Quiet Setting:</strong> Find a comfortable environment for discussion where the addict feels safe and at ease.</li>
            <li><strong>Listen Attentively:</strong> Allow them to express their feelings and thoughts without interruption or judgment.</li>
            <li><strong>Acknowledge Their Emotions:</strong> Use phrases like "I understand you feel scared" to open up a more extensive dialogue.</li>
        </ul>

        <h2>2. Set Clear Boundaries</h2>
        <p>While providing support is crucial, establishing clear boundaries is necessary to avoid enabling negative behaviors such as continued addiction. By setting boundaries, you communicate to the addict that they are responsible for their actions and decisions.</p>

        <h3>How to Establish Effective Boundaries:</h3>
        <ul>
            <li><strong>Be Direct:</strong> Clearly explain the behaviors you will not tolerate.</li>
            <li><strong>Keep Boundaries Flexible:</strong> While it’s important to set limits, be adaptable in how you enforce them.</li>
            <li><strong>Outline Consequences:</strong> Ensure the addict understands the repercussions of their behavior, such as losing support or resources.</li>
        </ul>

        <h2>3. Family Support</h2>
        <p>Family support is a critical element in the recovery journey, but it should be provided without covering up the addict's behaviors. The addict should feel that they are not alone in this journey while simultaneously being held accountable for their actions.</p>

        <h3>How Can Family Offer Support?</h3>
        <ul>
            <li><strong>Collaborate as a Team:</strong> Work with other family members to unify your message and support.</li>
            <li><strong>Avoid Blame:</strong> Focus on behaviors rather than placing personal blame.</li>
            <li><strong>Provide Resources:</strong> Offering information about treatment centers or support groups can be a positive step.</li>
        </ul>

        <h2>4. Consult Professionals</h2>
        <p>If the addict is unwilling to engage in direct treatment, they may be more receptive to sessions with a mental health professional or support groups. This step can be a good starting point to guide them toward treatment.</p>

        <h3>How to Choose the Right Professional:</h3>
        <ul>
            <li><strong>Look for Addiction Specialists:</strong> Ensure that the professional has experience dealing with addiction cases.</li>
            <li><strong>Inquire About Support Groups:</strong> Check if there are local support groups the addict can join.</li>
            <li><strong>Provide Positive Reinforcement:</strong> Emphasize that seeking professional help is a sign of strength and a desire for improvement.</li>
        </ul>

        <h2>5. Don’t Give Up</h2>
        <p>Convincing an addict to accept treatment can take time and patience. Avoid rushing to judgment or giving up hope. Being patient and understanding is a fundamental part of this process. Continue to support the addict cautiously, being present for them when they need you.</p>

        <h3>How to Stay Positive:</h3>
        <ul>
            <li><strong>Avoid Pressure:</strong> Do not push them to accept treatment, as this may lead to negative reactions.</li>
            <li><strong>Celebrate Small Successes:</strong> Acknowledge any progress they make, no matter how small.</li>
            <li><strong>Continue Offering Support:</strong> Remind the addict that you are there to support them regardless of the circumstances.</li>
        </ul>

        <h2>6. Support Yourself</h2>
        <p>It’s essential for you to seek your own emotional support or counseling. Dealing with an addict can be draining, so prioritizing your mental health and well-being is crucial.</p>

        <h3>How to Take Care of Yourself:</h3>
        <ul>
            <li><strong>Find Support Groups:</strong> Many groups offer support to family members dealing with addiction.</li>
            <li><strong>Set Aside Time for Yourself:</strong> Engage in activities you enjoy to enhance your mental well-being, whether through exercise, reading, or spending time with friends.</li>
            <li><strong>Consult a Specialist:</strong> Speaking with a mental health professional can help you process your own feelings.</li>
        </ul>

        <h2>Conclusion</h2>
        <p>Dealing with an addict who refuses treatment requires patience, strategy, and support from family and professionals. You are not alone in this journey, and there are always resources available to help you manage this situation. Remember that addiction is a disease that necessitates comprehensive treatment and long-term support.</p>
    `,
    title: "How to Handle an Addict Who Refuses Treatment",
    desc: "Dealing with an addict who refuses treatment is an emotionally challenging and complex situation. Family members often feel anxious, fearful, and confused by the addict's behavior, especially when they reject help. It’s crucial to understand that addiction is a complicated disease that requires a thoughtful approach. In this article, we will discuss practical steps to help you navigate this difficult situation, emphasizing the importance of family support and effective communication.",
  },
  {
    path: "/blog",
    id: "10",
    image: blogImage10,
    points: `
    <h1>Consequences of Delaying Addiction Treatment</h1>

<h2>Worsening Health and Psychological State</h2>
<p>The longer we delay seeking treatment, the more complex the situation becomes. Addiction does not remain stagnant; it evolves day by day. The effects of drugs or alcohol on the body intensify over time, leading to severe health issues such as liver, heart, and lung problems. Additionally, addiction deteriorates mental health. An addict may begin to experience feelings of isolation, depression, and hopelessness, complicating the recovery journey further.</p>

<h2>Increased Psychological Pressure</h2>
<p>Each postponement of treatment heightens the psychological pressure on both the addict and their family. The fear of confronting the issue or escaping it can intensify anxiety and stress. The addict may feel guilt and fear for the future, while the family struggles with worry for their loved one’s health. This stress may push the addict deeper into their addiction as a means of escaping reality, making future recovery more challenging.</p>

<h2>Negative Impact on Daily Life</h2>
<p>Delaying treatment affects not only health but also the addict's daily life. If the addict is a student, their academic performance can quickly decline. Concentration wanes, grades drop, and ultimately, they may face failure or expulsion from school or university. If the addict is employed, job performance deteriorates significantly. Frequent absences, mistakes at work, and decreased productivity can lead to job loss. These factors complicate the addict's life further, placing them in a more difficult position over time.</p>

<h2>Deterioration of Social Relationships</h2>
<p>Addiction significantly impacts social relationships. An addict may lose trust and support from friends and family due to irresponsible behaviors. Delaying treatment exacerbates the gap between the addict and their loved ones, potentially leading to the loss of a support network that can play a critical role in recovery. Without treatment, the addict may become completely isolated from society, increasing feelings of despair and depression.</p>

<h2>Risk of Relapse</h2>
<p>Delaying treatment exposes the addict to a higher risk of relapse. Even if they attempt to recover on their own or stop using substances for a time, the absence of appropriate professional treatment increases the likelihood of returning to addiction. Relapse becomes more difficult in this context, as the addict may have lost hope for recovery and become more susceptible to larger problems.</p>

<h2>Why Do People Postpone Treatment?</h2>

<h3>Education</h3>
<p>Many families postpone treatment due to concerns about its impact on academic performance or fear of losing a school year. However, it’s essential to recognize that addiction negatively affects education. An addict struggles to concentrate, and their grades decline rapidly, leading to the loss of educational opportunities altogether. A temporary break for treatment is far better than losing an academic chance entirely due to addiction. Education can be resumed later, but mental and physical health cannot be replaced.</p>

<h3>Employment</h3>
<p>Some postpone treatment out of fear of job loss or professional regression. This decision may seem reasonable initially, but the reality is that an addict’s declining work performance puts them at greater risk of losing their job. Inability to concentrate, frequent mistakes, and tardiness can all contribute to job loss more quickly. It is far better for the addict to take a short medical leave for treatment and return to work revitalized and performing better than risk job loss due to addiction.</p>

<h3>Debt</h3>
<p>Financial debt is another common reason for delaying treatment. Many individuals feel they cannot afford treatment or fear exacerbating their debts during the process. However, there are laws and programs that allow addicts to postpone or restructure their debts while undergoing treatment. Ultimately, mental and physical health should take precedence, and debts can be managed in other ways.</p>

<h2>What Is the Solution?</h2>
<p>The ideal solution is not to postpone treatment, regardless of the reasons. Life is indeed full of challenges, but health must always come first. Here are some tips for dealing with the reasons that lead some to postpone treatment:</p>

<h3>If the Issue Is Education</h3>
<p>The best approach is to speak with the school or university and request a temporary leave. Many educational institutions offer support for students facing health or psychological issues, allowing them to return after recovery to complete their studies.</p>

<h3>If the Issue Is Employment</h3>
<p>Don’t hesitate to speak with your supervisor or HR department and request a medical leave for treatment. Many companies recognize that addiction is a disease worthy of support, and they can help facilitate your return to work after recovery.</p>

<h3>If the Issue Is Debt</h3>
<p>Don’t let financial debt hinder your treatment. There are legal programs and solutions that assist addicts in postponing or restructuring their debts during treatment. You can explore these options through lawyers or relevant authorities.</p>

<h2>Conclusion</h2>
<p>Delaying addiction treatment is not the right choice. In fact, the sooner intervention occurs, the greater the chances of successful treatment. If you are facing this situation or know someone struggling with addiction, do not wait for circumstances to worsen. Seeking immediate support is the first step toward recovery. Addiction is a disease that requires serious attention, and delaying treatment will only exacerbate the problem. Early support and rapid intervention can make a significant difference in an addict's life, restoring hope for a healthy and stable future.</p>

    `,
    title: "Why You Should Avoid Delaying Addiction Treatment",
    desc: "Often, we hear about families postponing addiction treatment for a loved one due to the pressures of daily life. The reasons can vary—from academic or work obligations to financial constraints and stressors like debt. However, if you are one of those considering postponing treatment, it’s crucial to understand an important truth: delaying treatment is not the solution. In fact, it can exacerbate the problem and make recovery more complicated. Addiction is not a simple issue that resolves with time; it is a disease that requires prompt and immediate intervention. Each day that passes without treatment can increase health and psychological risks, putting the addict's life in greater jeopardy.",
  },
  {
    path: "/blog",
    id: "11",
    image: blogImage11,
    points: `
    <h1>Why Does This Yearning Occur?</h1>

<h2>Feelings of Loneliness</h2>
<p>Loneliness is one of the most challenging emotions an addict faces after beginning their recovery. Upon leaving their old environment, they find themselves in an unfamiliar world where feelings of isolation and lack of belonging can overwhelm them. In these moments, an addict often seeks anything that provides a sense of safety and belonging, driving them to yearn for past relationships and places.</p>
<p>Even if these relationships are harmful, they once offered the addict something important: acceptance and understanding without the need for justification or defense. In a new environment, they may feel judged by others based on their past, which intensifies their loneliness and desire to return to a place that did not impose such judgments.</p>

<h2>Shared Experiences</h2>
<p>Addiction uniquely bonds individuals. Friends who shared in an addict's period of substance use may have gone through similar experiences, fostering a kind of implicit understanding and easy communication. When an addict feels isolated in their new environment, they begin to reminisce about those shared moments with old friends. These memories, though filled with pain, also contain instances of understanding and deep connection.</p>
<p>These shared experiences make the addict yearn for those relationships, where they can express their feelings without the fear of being judged. In contrast, the new environment may feel foreign and unwelcoming, enhancing their desire to reconnect with former friends, even if those relationships were part of their initial problem.</p>

<h2>Acceptance Without Judgment</h2>
<p>In an environment dominated by addiction, an addict may feel accepted as they are, without facing judgment or criticism. Although this setting is filled with negatives, it provided what they needed at that moment: unconditional acceptance. Outside of this environment, the addict may feel criticized or judged for their past behaviors. This tension between the desire for recovery and the fear of social judgment can lead them to long for the environment that offered no such scrutiny. There, they may have felt secure and accepted, even if those relationships ultimately harmed them in the long run.</p>

<h2>Search for Identity</h2>
<p>When someone is deeply entrenched in addiction for an extended period, it can become a part of their identity. They grow accustomed to this identity and view themselves through that lens. As they begin their recovery journey, addicts must redefine themselves and construct a new identity separate from their addiction. This process can be daunting and confusing, as the individual must confront themselves and rediscover who they are beyond the framework of addiction.</p>
<p>During these moments of uncertainty and confusion, the addict may revert to what is familiar, even if it is toxic. The old environment provides a sense of security and identity, despite its detrimental nature. This comfort in self-identification within the context of addiction can be a significant reason behind the longing for that environment.</p>

<h2>Resistance to Change</h2>
<p>Change is inherently difficult, especially when a person has been accustomed to a certain lifestyle for an extended period. Addiction is a part of the addict's routine, and shifting away from it poses a significant challenge. The addict needs time and patience to adjust to their new life, and letting go of the old environment may evoke feelings of loss or grief.</p>
<p>Change requires considerable psychological and emotional effort, which can overwhelm the addict, pushing them to consider returning to what is familiar and easy. The old environment symbolizes comfort and familiarity, while the new one may seem daunting and uncomfortable.</p>

<h2>How Can a New Environment Be Built?</h2>
<p>For the addict to achieve full recovery and overcome their longing for their old environment, they must actively work to create a new, healthy environment that offers the support they need. Recovery is not merely a physical process; it is a holistic journey that encompasses psychological and emotional aspects as well. The new environment should be supportive and encourage growth and development.</p>

<h3>Develop a New Support Network</h3>
<p>The first step an addict should take on their recovery journey is to build a new support network consisting of friends and family who provide positive support. These individuals should understand the addict's needs and offer assistance without judgment. This new web of relationships can provide the acceptance and belonging that the addict sought in their old environment.</p>

<h3>Engage in New Activities</h3>
<p>A life filled with positive and constructive activities can help the addict divert their thoughts from returning to the toxic environment. Participating in new activities, such as sports or learning a new hobby, can offer the addict opportunities to form new relationships and discover positive aspects of their personality.</p>

<h3>Find an Environment That Encourages Growth</h3>
<p>It is vital for the addict to seek out environments that support their growth and development, such as support groups or communities that promote a healthy lifestyle. These settings provide the necessary psychological and emotional support for the addict to feel stable and comfortable.</p>

<h3>Seek Professional Support</h3>
<p>Psychological therapy and counseling can aid the addict in processing their feelings and nostalgia for the past. Professional support can equip the addict with the tools necessary to cope with psychological pressures and challenges they may encounter during their recovery journey.</p>

<h2>Conclusion</h2>
<p>Longing for an old environment is neither strange nor irrational for an addict. This environment, despite being toxic and harmful, represents a sense of belonging and acceptance during moments of weakness. However, to achieve full recovery, it is crucial for the addict to work on building a new, healthy environment that offers the support and encouragement they need.</p>

    `,
    title: "Why Do Addicts Yearn for Their Toxic Old Environments?",
    desc: "Addiction is not merely a bad habit that can be easily shaken off; it is a complex disease that affects both the body and mind. Throughout their recovery journey, addicts face numerous challenges, one of the most prominent being the persistent desire to return to the environment that contributed to their addiction, despite its toxic and stressful nature. Many may wonder why an addict longs for a place they know is harmful, but the answer is not straightforward.The old environment represents more than just a location or a group of people for the addict; it is a part of their life, history, and personal experience. While this environment may have led to or supported their harmful behaviors, it also provided a sense of safety and acceptance during moments of vulnerability, explaining the addict's longing to return",
  },
  {
    path: "/blog",
    id: "12",
    image: blogImage12,
    points: `
    <h2>Addiction and Genetic Factors</h2>

<p>Addiction is not purely hereditary; it does not pass from parents to children in the same manner as genetic traits like eye color or height. However, certain genetic factors can increase an individual's predisposition to addiction. Studies suggest that approximately 40% to 60% of the risk for addiction can be attributed to genetic factors. Yet, this predisposition is not the only determinant; environmental influences and upbringing also play significant roles.</p>

<h3>The Importance of Genes in Addiction Vulnerability</h3>

<p>Research indicates that genetics may influence how individuals respond to addictive substances. Some individuals may possess genes that make them more susceptible to addiction, particularly those associated with psychological responses to specific substances.</p>

<ul>
    <li><strong>Psychological Vulnerability:</strong> Some studies suggest that individuals with a family history of addiction may have a greater psychological vulnerability to developing addiction issues. This vulnerability can manifest as increased sensitivity to drugs or difficulties in controlling behaviors.</li>
    <li><strong>Genetics and Substance Response:</strong> Certain research has identified specific genes associated with how individuals respond to particular substances. For instance, individuals with certain gene variations may experience stronger effects from drugs, increasing the likelihood of excessive use.</li>
    <li><strong>Gene-Environment Interaction:</strong> It is crucial to understand that genes do not operate in isolation. There is an interaction between genetic and environmental factors. While genes may heighten a person's susceptibility to addiction, the surrounding environment and individual upbringing play a pivotal role in determining whether addiction-related behaviors will develop.</li>
</ul>

<h3>Influencing Factors on Addiction</h3>

<p>Although genetics contribute to addiction vulnerability, environmental, parenting, and personal choices play even more significant roles.</p>

<ul>
    <li><strong>Social Environment</strong>
        <ul>
            <li><strong>Surrounding Influences:</strong> The social environment plays a critical role in shaping individual behaviors. Friends, family, and the community can significantly impact a person's decisions regarding drug use. For example, if someone is surrounded by individuals who use drugs, they are more likely to engage in similar behaviors.</li>
            <li><strong>Safety and Support:</strong> Children raised in safe and supportive environments experience stability, which reduces the risk of engaging in dangerous behaviors. Emotional support from family can help children overcome challenges and cope with stress more effectively.</li>
        </ul>
    </li>
    <li><strong>Parenting</strong>
        <ul>
            <li><strong>Education and Guidance:</strong> Parents who provide clear guidance and emotional support can lower the risk of addiction in their children. Teaching children about the dangers of drugs and alcohol and how to make sound decisions is a vital step in prevention.</li>
            <li><strong>Values and Expectations:</strong> The values instilled in children from a young age significantly influence their future behavior. Children who grow up in environments that promote healthy behaviors and provide positive role models are less likely to engage in addictive behaviors.</li>
        </ul>
    </li>
</ul>

<h3>How to Protect Our Children from Addiction Risks</h3>

<ul>
    <li><strong>Early Awareness</strong>
        <ul>
            <li>Educating children early about the dangers of addiction and how to make sound decisions is a crucial first step in prevention. It's essential for children to learn about the effects of drugs and alcohol and understand the potential consequences of their use.</li>
            <li><strong>Open Conversations:</strong> There should be open dialogue between parents and children about the risks associated with drugs. This discussion can help reduce curiosity about substances and strengthen children's decision-making abilities.</li>
            <li><strong>Providing Accurate Information:</strong> Children should receive accurate information about drugs and their effects on the body and mind. The better their understanding, the more equipped they are to avoid engaging in risky behaviors.</li>
        </ul>
    </li>
    <li><strong>Fostering a Healthy Family Environment</strong>
        <ul>
            <li>Creating a family atmosphere filled with love and emotional support can mitigate addiction risks. When children feel safe and supported at home, they are less likely to seek escape in drugs or alcohol.</li>
            <li><strong>Encouraging Communication:</strong> Parents should foster open communication with their children, helping them feel comfortable discussing their feelings and challenges.</li>
            <li><strong>Emotional Support:</strong> Providing emotional support helps children develop coping skills for dealing with stress and challenges, reducing the likelihood of using drugs as a means of escape.</li>
        </ul>
    </li>
    <li><strong>Early Psychological Support</strong>
        <ul>
            <li>If you notice any negative signs, such as increased isolation or stress in your child, seeking psychological support early on can help prevent these feelings from escalating.</li>
            <li><strong>Consulting Professionals:</strong> There should be an awareness of the importance of early intervention. If a child is experiencing psychological issues, it's vital to seek support from professionals.</li>
            <li><strong>Promoting Mental Well-being:</strong> Offering psychological and emotional support to children can enhance their mental well-being and reduce the likelihood of drug use.</li>
        </ul>
    </li>
</ul>

<h2>Conclusion: Addiction Is Not Inevitable</h2>

<p>While genetic factors may heighten the risk of addiction, this does not mean that children will inevitably become addicts. With proper care, ongoing education, and a supportive family environment, children can lead healthy lives free from the threat of addiction.</p>

<h3>Positivity and Encouragement</h3>

<p>When addressing addiction, it is essential to emphasize that hope always exists. Despite the challenges families may face, creating a supportive environment and teaching children the necessary skills to cope with stress can yield positive outcomes.</p>

<h3>Community Role</h3>

<p>The entire community should collaborate to support families and individuals facing addiction challenges. Providing resources for assistance and psychological support can have a positive impact on overall community health.</p>

    `,
    title:
      "Is Drug Addiction Hereditary? Understanding the Genetic Influence on Addiction",
    desc: "Addiction is a serious health issue that impacts many individuals and their families. A common question arises: 'Can addiction be inherited?' If a parent is an addict, are their children equally at risk for addiction? These questions are crucial, as they influence our understanding of addiction and how we approach it. In this article, we will explore the relationship between genetics and addiction, highlighting the influencing factors, and discussing ways to protect our children from the risk of addiction.",
  },
  {
    path: "/blog",
    id: "13",
    image: blogImage13,
    points: `
    <h2>The Role of Rehabilitation Centers in Addiction Treatment</h2>

<p>Rehabilitation centers serve as essential facilities for treating addiction, providing medical and psychological care to individuals struggling with substance abuse. In these safe environments, healthcare professionals focus on detoxification, a necessary step to initiate recovery. During this period, withdrawal symptoms, which can be severe and challenging, are managed.</p>

<p>However, rehabilitation cannot be the final solution. Once treatment in the facility concludes, the recovering individual faces a new challenge: how to maintain sobriety in daily life. This is where psychological and social support becomes critical.</p>

<h3>What Patients Need After Leaving Rehabilitation</h3>

<p>After leaving a rehabilitation center, individuals require several key elements to ensure a successful recovery journey:</p>

<ul>
    <li><strong>Ongoing Psychological Treatment:</strong> One of the most crucial steps after exiting rehabilitation is continuing psychological treatment. Therapy sessions are vital for understanding the underlying causes of addiction, such as anxiety, depression, or challenging social circumstances. Through therapy, individuals can learn how to cope with their emotions and challenges in healthy ways.</li>
    <li><strong>Social Support:</strong> The importance of social support in the recovery process cannot be overstated. This support includes family, friends, and colleagues. When individuals feel supported by those around them, they are more motivated to stay on track.</li>
    <li><strong>Life Skills Training:</strong> Individuals must learn essential life skills to manage their daily lives without resorting to drugs. These skills may include coping with stress, time management, and job searching.</li>
</ul>

<h3>Challenges of Recovery After Rehabilitation</h3>

<p>Recovering individuals may face various challenges after leaving rehabilitation, including:</p>

<ul>
    <li><strong>Social Pressures:</strong> After treatment, a recovering individual may find themselves in environments filled with social pressures. Returning to friends who do not understand the recovery process can be difficult. It's important for individuals to build a new network of supportive friends who encourage sobriety.</li>
    <li><strong>Coping with Daily Stressors:</strong> Daily life comes with its challenges, and it's essential for recovering individuals to learn how to manage these pressures healthily. Work-related stress or personal relationship challenges can be tough, making it vital to have effective coping strategies.</li>
    <li><strong>Anxiety and Depression:</strong> Many individuals in recovery may struggle with mental health issues such as anxiety and depression, which may have contributed to their addiction in the first place. Continuing psychological treatment is crucial for managing these issues. Some may require medication to help control symptoms of anxiety and depression.</li>
</ul>

<h3>Continuing Recovery: Practical Steps</h3>

<p>Recovery is a long-term process that requires commitment and ongoing support. Here are some steps that can enhance the chances of success:</p>

<ul>
    <li><strong>Setting Goals:</strong> It’s important to establish short- and long-term goals. Goals provide a clear vision of what the individual wants to achieve. Daily goals, such as attending therapy sessions or exercising, as well as larger goals like finding stable employment, are beneficial.</li>
    <li><strong>Participating in Support Groups:</strong> Joining support groups can be extremely helpful. These groups offer a safe environment to discuss challenges and share experiences with others. Groups like "Alcoholics Anonymous" or "Narcotics Anonymous" can provide significant inspiration and support.</li>
    <li><strong>Staying Active:</strong> Maintaining physical activity helps improve mood and overall health. Engaging in physical activities like sports or daily walks can be part of a healthy routine.</li>
    <li><strong>Learning from Mistakes:</strong> Relapses may occur during the recovery journey, but it’s important to learn from them rather than feel discouraged. Each experience can serve as a valuable lesson.</li>
    <li><strong>Prioritizing Mental Health:</strong> Mental well-being should be a priority. Self-care strategies may include mindfulness, yoga, or reading inspiring books.</li>
</ul>

<h2>Conclusion</h2>

<p>Recovering from addiction is a journey that requires continuous support from family and the community. Rehabilitation is a crucial step in this journey, but it is not the end. To achieve success in the recovery process, there must be a commitment to psychological treatment, social support, and the teaching of essential life skills.</p>

<p>If you are struggling with drug addiction or know someone in need of help, do not hesitate to seek support. Recovery is possible, and ongoing support is the key to success. Remember that every step towards recovery is a step towards a better life.</p>

    `,
    title: "Is Rehabilitation Enough for Treating Drug Addiction?",
    desc: "When discussing addiction treatment, a common question arises: 'Is rehabilitation enough to treat drug addiction?' To answer this question, it's essential to understand that rehabilitation represents only the beginning of the recovery journey. While detoxification is a crucial phase, comprehensive treatment requires ongoing effort and support after leaving the rehabilitation center. In this article, we will explore the role of rehabilitation centers in treating addiction and what individuals need to achieve complete recovery.",
  },
  {
    path: "/blog",
    id: "14",
    image: blogImage14,
    points: `
    <h1>Addiction and Marriage: Can They Coexist?</h1>
        <p>Marriage is a significant responsibility that requires psychological, physical, and emotional readiness. For someone struggling with drug addiction, this readiness may be lacking due to the psychological and physical effects of substance abuse. Numerous studies indicate that addiction is a long-term disorder requiring ongoing treatment and careful monitoring to ensure sustainable recovery. While marriage may offer some emotional stability, the reality is that marrying without addressing the addiction may increase tensions and worsen the situation.</p>

        <h2>Why Focus on Recovery First?</h2>

        <h3>Restoring Psychological and Physical Stability</h3>
        <p>Addiction profoundly affects a person's mental and physical health. Often, individuals battling addiction suffer from mental health disorders such as depression or anxiety, which require specialized treatment. Entering into a marriage before regaining psychological balance may exacerbate these issues, potentially leading to failed relationships and an increased risk of relapse into addiction.</p>

        <h3>Risk of Relapse</h3>
        <p>Recovery from addiction is a long-term process. Experts in addiction treatment suggest that achieving stability can take at least two years. During this time, recovering individuals face the risk of relapse, especially if they encounter psychological or emotional stress. Marriage inherently demands substantial emotional and psychological effort; if the individual has not fully regained their health, marriage could contribute to a return to substance abuse.</p>

        <h3>Marriage and Social Pressures</h3>
        <p>Marriage entails not only a commitment to a partner but also navigating family and societal expectations. For individuals in recovery, managing social and familial pressures can be overwhelming, increasing the likelihood of relapse. Before considering marriage, the individual should be able to handle these pressures healthily, without resorting to drugs as a means of escape.</p>

        <h2>Stages of Recovery and Their Relation to Marriage</h2>

        <h3>Detoxification Phase</h3>
        <p>Detoxification is the first step in addiction treatment, aimed at helping the individual stop drug use safely and quickly. This phase can be psychologically and physically painful and requires careful monitoring by a specialized medical team. Marrying during this phase may add psychological burdens and distract from the focus on recovery.</p>

        <h3>Psychological and Emotional Treatment Phase</h3>
        <p>After detoxification, psychological treatment focuses on addressing the deep-rooted causes of addiction. This treatment may take months or even years and requires complete commitment from the individual to engage in therapy sessions and manage emotions appropriately. Marriage during this phase can be challenging since the individual is still in the process of regaining their psychological balance.</p>

        <h3>Social Reintegration Phase</h3>
        <p>Social reintegration is the stage where the recovering individual gradually returns to their social and professional life. At this point, they may be more prepared to consider marriage, provided they have fully restored their psychological and physical stability. It is crucial for mental health professionals to assess the individual’s condition before making any decisions regarding marriage.</p>

        <h2>Risks of Marrying Before Full Recovery</h2>

        <h3>Emotional Pressures</h3>
        <p>Marital relationships demand patience and understanding. For someone battling addiction, dealing with the emotional pressures associated with marriage—such as financial challenges, family dynamics, and daily communication—can be particularly difficult. If the individual has not fully recovered, marriage may exacerbate their psychological problems and increase the likelihood of returning to substance use.</p>

        <h3>Toxic Relationships</h3>
        <p>Entering into an unstable marriage can lead to a toxic relationship, raising the risk of relapse. Toxic relationships create unhealthy environments that can pull the individual back into addiction. Therefore, it is essential for the person to be in a state of psychological stability before committing to any long-term relationship.</p>

        <h3>Impact on the Partner</h3>
        <p>The partner in a marriage with an addicted individual also faces significant challenges. They may experience feelings of isolation or frustration due to the other person’s inability to communicate healthily. In some cases, this can lead to relationship breakdowns and worsen the addiction issues.</p>

        <h2>Advice for Parents: Support Recovery First</h2>
        <p>It is natural for parents to want to see their children live normal, stable lives. However, the best initial focus should be on supporting the recovery journey. Before considering marriage as a solution, it’s vital to ensure that the individual has regained psychological and emotional stability. Marriage can be a positive step for a recovering individual, but this decision should be made with the guidance of medical professionals and addiction specialists.</p>

        <h2>Frequently Asked Questions</h2>

        <h3>Can marriage help in recovering from addiction?</h3>
        <p>Marriage is not a cure for addiction. While it may provide emotional support, the individual must be in a stable mental state before entering into a marital relationship.</p>

        <h3>What are the best ways to support an addicted son?</h3>
        <p>The best approach is to provide emotional support, encourage them to seek treatment from addiction specialists, and avoid pressuring them into major decisions like marriage before they are ready.</p>

        <h3>Is there a specific time to consider marriage after recovery?</h3>
        <p>Experts recommend waiting until the individual is psychologically and physically stable, which typically takes at least two years.</p>

        <h2>Conclusion</h2>
        <p>Addiction is a complex disease requiring long-term treatment and ongoing support. Recovery must be the top priority before considering steps like marriage. Consulting with mental health professionals before making any life-changing decisions is crucial to ensure a healthy and stable future for both the recovering individual and their partner.</p>

    `,
    title:
      "Should a Son Be Married If He Is Addicted to Drugs? An In-Depth Look at the Relationship Between Addiction and Marriage",
    desc: "When facing a child’s addiction, many parents wonder if marriage could be a solution to alleviate the addiction or even contribute to its treatment. However, before making any decisions, it’s essential to consider the potential consequences and challenges involved. In this article, we will explore why prioritizing recovery is the best option and how marriage can impact the journey of recovering from drug addiction.",
  },
  {
    path: "/blog",
    id: "15",
    image: blogImage15,
    points: `
     <h1>Understanding Addiction and Its Treatments</h1>
        <p>Before discussing the side effects of addiction treatments, it's essential to comprehend addiction itself. Addiction is a complex condition characterized by psychological and physical dependence on certain substances, such as drugs or alcohol. The recovery process requires a comprehensive approach that combines behavioral therapies and medication. The medications used in treatment aim to alleviate withdrawal symptoms and reduce cravings, but they can also come with side effects.</p>

        <h2>Common Side Effects of Addiction Treatments</h2>

        <h3>Mood Swings</h3>
        <p>Mood fluctuations are among the most common side effects. Some medications can cause depression or anxiety, leading to sharp changes in emotional states. Individuals in recovery may experience periods of extreme euphoria followed by bouts of sadness or depression. These mood swings can negatively impact social relationships and make daily life more challenging.</p>

        <h3>Sleep Problems</h3>
        <p>Many people undergoing addiction treatment struggle with sleep issues, including insomnia and difficulty achieving deep sleep. Lack of sleep can result in fatigue and general weakness, affecting concentration and attention. Establishing a regular sleep routine and avoiding stimulants like caffeine before bed can help improve sleep quality.</p>

        <h3>Unexpected Physical Effects</h3>
        <p>Unwanted physical effects may include weight gain, sudden weight loss, or even hair loss. Some medications can impact appetite, causing individuals to eat less or more than usual. These physical changes can increase psychological stress, especially if the individual is concerned about their appearance.</p>

        <h3>Concentration and Attention Issues</h3>
        <p>The impact of certain medications on concentration can pose significant barriers to recovery. Individuals may struggle to complete daily tasks or even pay attention during conversations. It is advisable to seek strategies for improving focus, such as deep breathing techniques or relaxation methods.</p>

        <h3>Digestive Issues</h3>
        <p>Many medications can affect the digestive system, leading to nausea, constipation, or diarrhea. These problems can hinder the ability to eat normally, potentially worsening overall health.</p>

        <h2>How to Manage Side Effects of Addiction Treatments</h2>

        <h3>Carefully Monitor Changes</h3>
        <p>It is crucial for individuals and their families to be aware of any unusual changes in behavior or physical health. The recovering individual should document any new changes, including mood, energy levels, and health issues. This will enable them to present their condition accurately to their healthcare provider.</p>

        <h3>Communicate with the Doctor Immediately</h3>
        <p>If any unexpected side effects occur, it's important to contact the healthcare provider right away. The individual may need an adjustment in medication dosage or a change in treatment if the side effects adversely affect their quality of life. Don't hesitate to ask questions and seek clarifications regarding any side effects.</p>

        <h3>Patience and Follow-Up</h3>
        <p>Some side effects may be temporary and resolve over time. However, it’s vital to continue monitoring them and ensure they do not worsen. Regular communication with the medical team is key to ensuring an effective treatment response.</p>

        <h2>Importance of Family and Community Awareness</h2>

        <h3>Awareness</h3>
        <p>Awareness is crucial for ensuring the success of the recovery process. Families must understand the challenges individuals may face during addiction treatments. Continuous support and understanding from family can significantly impact the success of recovery efforts.</p>

        <h3>Providing a Supportive Environment</h3>
        <p>The family plays a vital role in creating a supportive atmosphere. When individuals feel supported and understood by their families, they are more capable of facing challenges. Engaging in shared family activities, such as sports or arts, can strengthen familial bonds and improve the mental health of the recovering individual.</p>

        <h3>Community Awareness</h3>
        <p>The community should be educated about addiction and its treatments. Organizing workshops and lectures can help remove the stigma surrounding addiction. A collective understanding of the issue promotes support for recovering individuals and encourages open dialogue about the negative impacts of addiction.</p>

        <h2>Conclusion</h2>
        <p>While medications used for treating addiction can be highly beneficial, it is essential to handle the potential side effects with care. A good understanding of possible effects and ongoing communication with the medical team can facilitate safe and sustainable recovery. Additionally, it is crucial to emphasize the roles of family and community in supporting recovering individuals and easing their path toward healing.</p>

    `,
    title:
      "Are Addiction Treatments Dangerous? Understanding Side Effects and Their Impact",
    desc: "The journey to recovery from addiction is one of the toughest paths individuals and their families can traverse. With advancements in research and developments in addiction treatment, a variety of medications and techniques have emerged to help individuals recover. However, a crucial question remains: Are these treatments safe? What side effects should we be aware of? In this article, we will explore the side effects of addiction treatments, how to manage them, and the importance of awareness in families and communities.",
  },
  {
    path: "/blog",
    id: "16",
    image: blogImage16,
    points: `
    <h1>Why Should We Talk to Children About Their Parent's Addiction?</h1>

        <h2>Emotional Support for the Parent</h2>
        <p>When children understand the truth about their parent's situation, they can provide genuine emotional support. Creating an environment where the parent feels supported by their family can significantly impact their self-esteem and determination to overcome challenges. The support from children reinforces the parent's awareness of the importance of treatment and encourages them to face their problems more seriously. Sometimes, knowing that there are loved ones who care can be the motivating force for change.</p>

        <h2>Increased Empathy and Understanding</h2>
        <p>Helping children understand what their parent is going through fosters greater empathy and awareness. Rather than viewing their parent as weak or irresponsible, children learn that addiction is a disease that many individuals face. This understanding will help them recognize that their parent is not alone in this battle, enhancing their capacity to offer support and compassion. It will also develop their character and empathy toward others in the future.</p>

        <h2>Motivation for Personal Change</h2>
        <p>The love and support from children can serve as a powerful motivator for a parent to begin their recovery journey. When the parent realizes they are not alone and that their family stands by them, they may feel a greater drive to change. This emotional support can be the push the parent needs to overcome obstacles and move toward recovery. Children will undoubtedly play a significant role in transforming their parent's life for the better.</p>

        <h2>Building a Family Support Network</h2>
        <p>Openly discussing the issue with children allows the entire family to work together as a team to support the parent in their recovery journey. Establishing a support network within the family is vital for the success of treatment. A united family is better equipped to face challenges and provide the necessary support for the parent. In this way, children can feel that they are part of the solution, enhancing their sense of belonging and contribution.</p>

        <h2>How to Approach the Conversation About a Parent's Addiction</h2>

        <h3>Be Honest and Simple</h3>
        <p>When talking to children, it’s essential to be straightforward and use simple language that is appropriate for their age. The information should be clear and understandable. Avoid complex terminology that might confuse them. You could say, "Your dad is having difficulty dealing with something called addiction, and he needs our help." Be prepared to answer any questions they might ask, even if those questions are challenging or confusing.</p>

        <h3>Clarify Their Role in Providing Support</h3>
        <p>Emphasize to the children that they are an important part of the solution. Help them understand that their support positively impacts their parent. You might say, "Your presence helps your dad feel he’s not alone." This can instill a sense of responsibility and belonging in them, reinforcing family bonds.</p>

        <h3>Provide Safety and Reassurance</h3>
        <p>It’s important to reassure children that things will improve and that you are always there to support them. Children may feel anxious or fearful, so creating a safe environment that fosters trust and emotional security is crucial. You could say, "We will be together as a family and work together to help him recover." This message helps them understand they are not alone in this journey.</p>

        <h3>Listen to Their Feelings and Concerns</h3>
        <p>Encourage children to express their feelings and be supportive of any worries or questions they may have. Listening to them builds trust and enhances communication. Sometimes, children may need time to process the information, so be patient and prepared for ongoing discussions. Your family needs to be strong and cohesive, which requires everyone to feel heard and understood.</p>

        <h3>Encourage Family Interaction</h3>
        <p>Incorporate discussions about addiction into broader family conversations. Set aside time for the family to talk about daily challenges and successes. These conversations can include topics like managing stress or improving communication skills. Fostering positive interaction among family members will help create a more supportive and understanding environment.</p>

        <h2>The Importance of Ongoing Support</h2>
        <p>Discussing a parent's addiction with children is a courageous step, but it is necessary. However, it is important to recognize that this is not the end of the journey; rather, it marks the beginning of an ongoing process of support and communication. Being transparent with children about their parent's addiction not only benefits the parent but also strengthens family bonds and creates a supportive environment.</p>

        <p>We must continue the dialogue about mental health and addiction, promoting empathy and understanding within the family. Children need to know that the challenges they face are a part of life, and they can be a source of support for their loved one during tough times.</p>

        <h2>Conclusion</h2>
        <p>Open and loving communication can build strong support and help the entire family in the recovery process. Remember that this topic can be sensitive, so approach the conversation with kindness and patience. While things may seem difficult now, through support and communication, the family can overcome these challenges together.</p>

        <p>Talking about addiction with children is a brave step but is essential for building a better future. Although things may seem tough right now, with support and communication, families can navigate these challenges together.</p>

    `,
    title: "Should We Talk to Our Children About Their Parent's Addiction?",
    desc: "The topic of a parent's addiction is one of the most sensitive and complex issues families may face. It’s crucial to discuss this matter transparently, as addiction affects not only the individual struggling with it but also extends its impact to all family members. In this article, we will explore the importance of communicating with children about their parent's addiction, the benefits that can arise from this dialogue, and practical tips to help you navigate this challenging conversation.",
  },
  {
    path: "/blog",
    id: "17",
    image: blogImage17,
    points: `
    <h2>The Importance of Understanding Addiction</h2>

<p>Addiction is a complex medical condition that goes beyond poor decisions or a lack of willpower. It is a disease that alters the brain’s function and chemistry, which significantly impacts an individual's behavior and decision-making. Understanding addiction as a medical condition is crucial because it sets the stage for recovery. It is important to approach addiction with compassion, recognizing that the person suffering from it needs support, treatment, and time to heal. The goal of recovery is not just to stop using substances, but to improve the quality of life, rebuild relationships, and help individuals reach their personal and professional goals.</p>

<h3>What Does Recovery from Drug Addiction Require?</h3>

<h4>1. Appropriate Treatment</h4>

<p>The first and most vital component of recovery is treatment. Recovery often involves various forms of therapy tailored to the individual’s needs:</p>

<ul>
  <li><strong>Behavioral Therapy</strong>: This type of therapy helps individuals recognize and change negative thought patterns and behaviors that contribute to addiction. It may include individual sessions or group therapy.</li>
  <li><strong>Medication-Assisted Treatment (MAT)</strong>: In some cases, medications can help reduce cravings and alleviate withdrawal symptoms, supporting the individual through the detoxification process.</li>
  <li><strong>Comprehensive Therapy</strong>: In many cases, there are underlying psychological issues, such as anxiety or depression, that contribute to the addiction. Therapy aimed at addressing these issues can make a significant difference in the recovery process.</li>
</ul>

<h4>2. Family Support</h4>

<p>The role of family in addiction recovery cannot be overstated. A supportive family can greatly enhance the likelihood of success in recovery:</p>

<ul>
  <li><strong>Reinforcing Commitment</strong>: Family members who encourage and support the addict throughout treatment help the individual stay motivated and committed to the process.</li>
  <li><strong>Creating a Safe Environment</strong>: A stable and healthy home environment can minimize stressors and potential triggers that might cause a relapse.</li>
  <li><strong>Engaging Friends</strong>: Friendships are also important. Encouraging friends to be part of the recovery process helps provide an added layer of support.</li>
</ul>

<h4>3. Strong Willpower</h4>

<p>Recovery requires the addict’s own commitment to change. The individual must:</p>

<ul>
  <li><strong>Have a Willingness to Change</strong>: Real change requires a strong desire to overcome addiction and improve one’s life.</li>
  <li><strong>Set Goals</strong>: Establishing realistic and attainable goals allows individuals to measure their progress and stay motivated.</li>
</ul>

<h4>4. The Psychological Aspect of Recovery</h4>

<p>After overcoming physical dependence, the psychological side of addiction recovery presents another set of challenges:</p>

<ul>
  <li><strong>Managing Psychological Stress</strong>: Learning to cope with life’s challenges without resorting to substances is key to long-term recovery.</li>
  <li><strong>Psychological Support</strong>: Therapy and support groups play a crucial role in helping individuals process their emotions, develop coping mechanisms, and stay mentally healthy.</li>
</ul>

<h4>5. The Importance of Sustainable Recovery</h4>

<p>Sustainable recovery involves ongoing efforts to maintain mental and emotional health:</p>

<ul>
  <li><strong>Stress Management</strong>: Learning effective techniques for managing stress and emotional turmoil is essential to avoiding relapse and maintaining progress.</li>
  <li><strong>Mental Health Monitoring</strong>: Engaging in self-care, including proper nutrition, exercise, and mental health routines, ensures a balanced and healthy life after addiction.</li>
</ul>

<h3>Life After Addiction</h3>

<p>Recovery is a comprehensive process that goes beyond the physical aspect of drug cessation. It is about rebuilding one’s life, including:</p>

<ul>
  <li><strong>Rebuilding Relationships</strong>: Addiction often strains family and social bonds. Rebuilding these relationships is a significant part of recovery and involves communication, trust, and healing.</li>
  <li><strong>Developing New Skills</strong>: Pursuing new activities or developing new skills can help recovering individuals feel fulfilled, improve self-esteem, and gain a sense of accomplishment.</li>
  <li><strong>Planning for the Future</strong>: Setting new personal, professional, and health-related goals can help individuals create a positive vision for their post-addiction life.</li>
</ul>

<h3>Preventing Relapse</h3>

<p>While relapse is a part of many recovery journeys, its chances can be minimized by:</p>

<ul>
  <li><strong>Continuous Support</strong>: A reliable support system is key to helping individuals resist triggers and remain committed to their recovery.</li>
  <li><strong>Coping Strategies</strong>: Learning and applying healthy coping strategies for dealing with stress, anxiety, and cravings significantly reduce the likelihood of relapse.</li>
</ul>

<h3>Conclusion</h3>

<p>In conclusion, recovery from addiction is a challenging but achievable goal. With the right treatment, family support, and personal commitment, individuals can overcome addiction and rebuild their lives. Recovery is not a quick fix but a lifelong journey of growth, self-improvement, and healing. The road to recovery may be tough, but the possibilities for a fulfilling and meaningful life are endless.</p>

    `,
    title:
      "Is Complete Recovery from Drug Addiction Possible? A Comprehensive Guide to Understanding the Recovery Journey",
    desc: "Drug addiction is a complex disease that impacts every aspect of an individual's life, from physical and mental health to social and occupational relationships. It is essential to understand that complete recovery from drug addiction is possible, but it requires time, effort, and strong support. In this article, we will explore the reasons that make recovery attainable and discuss the steps necessary to achieve it.",
  },
  {
    path: "/blog",
    id: "18",
    image: blogImage18,
    points: `
    <h2>What is Addiction?</h2>

<p>Addiction is a medical condition that affects both the mind and body, leading an individual to rely continuously on a specific substance or behavior to the extent that they cannot stop, despite recognizing the health, psychological, and social risks involved. This makes treatment more complicated, as it involves not only removing the addictive substance from the body but also addressing the psychological and social factors that contributed to the addiction in the first place.</p>

<h2>Is There a Quick Cure?</h2>

<p>Unfortunately, there is no quick cure for addiction. This notion, promoted by some online sources or unreliable information, can do more harm than good. Effective treatment requires a comprehensive approach that includes:</p>

<ul>
  <li>Detoxification.</li>
  <li>Psychological therapy.</li>
  <li>Social support.</li>
  <li>Developing new skills to face life without reliance on addictive substances.</li>
</ul>

<h2>The Addiction Treatment Journey: How Does It Start?</h2>

<p>The treatment for addiction is a process that requires patience and commitment. It begins with setting goals and working toward them step by step. Here are the main stages that an individual struggling with addiction must go through to achieve full recovery:</p>

<h3>Detoxification Phase</h3>

<p>The first step in the addiction treatment journey is detoxification, where the addictive substances are removed from the addict's body. This stage is often one of the most challenging, as the individual experiences painful physical and psychological withdrawal symptoms. Therefore, it is crucial for this phase to be supervised by medical professionals to ensure the safety of the addict and mitigate the severity of symptoms.</p>

<p>Withdrawal symptoms vary depending on the type of addiction and the duration of substance use, but they commonly include:</p>

<ul>
  <li>Severe headaches.</li>
  <li>Anxiety and depression.</li>
  <li>Excessive sweating.</li>
  <li>Muscle cramps.</li>
  <li>Sleep disturbances.</li>
</ul>

<p>These symptoms may last for several days or even weeks, making this phase critical. Managing withdrawal symptoms is the primary goal during this stage, often achieved through medications that help alleviate discomfort and provide psychological support.</p>

<h3>Psychological Therapy</h3>

<p>After completing the detoxification phase, the individual enters the next stage of treatment: psychological therapy. This is a vital part of the recovery process, as it helps the individual understand the psychological and underlying reasons that led to addiction. Through therapy sessions, the individual learns how to cope with the psychological and emotional pressures that may have driven them to addiction.</p>

<p>Psychological therapy can be individual or group-based and includes various techniques, such as:</p>

<ul>
  <li><strong>Cognitive Behavioral Therapy (CBT)</strong>: Assists in changing negative thought patterns that lead to addiction.</li>
  <li><strong>Group Therapy</strong>: Allows individuals to share their experiences and support one another.</li>
  <li><strong>Family Therapy</strong>: Aims to improve familial relationships and support the individual by providing a nurturing environment.</li>
</ul>

<h3>Social Support</h3>

<p>Social support is a critical factor in the success of addiction treatment. After completing psychological therapy, individuals need to reintegrate into society and begin building a new life without reliance on addictive substances. Family and friends play a vital role in providing this support. The individual needs an encouraging and supportive environment to maintain recovery and avoid relapse.</p>

<p>Family and friends can contribute significantly by:</p>

<ul>
  <li>Encouraging the individual to stay committed to treatment.</li>
  <li>Helping them navigate daily challenges without resorting to drugs.</li>
  <li>Providing emotional support during difficult moments.</li>
</ul>

<h3>Developing New Skills</h3>

<p>A significant part of recovery involves learning new skills that help the individual face life without reliance on addictive substances. These skills include:</p>

<ul>
  <li><strong>Stress Management</strong>: Learning how to cope with psychological pressures without resorting to addiction.</li>
  <li><strong>Effective Communication</strong>: Improving personal and social relationships.</li>
  <li><strong>Future Planning</strong>: Setting new goals and working toward achieving them.</li>
</ul>

<p>Learning these skills helps individuals enhance their quality of life and adapt to a drug-free existence, thus reducing the chances of relapse.</p>

<h2>Factors Contributing to Successful Treatment: What Does the Individual Need?</h2>

<ul>
  <li><strong>Long-Term Commitment and Strong Willpower</strong>: Overcoming addiction requires long-term commitment and strong willpower. The individual must be prepared to face daily challenges that may arise, such as cravings to return to substance use. This determination comes from a genuine desire for recovery and the support they receive from their surroundings.</li>
  <li><strong>Real Support from Family and Professionals</strong>: Having a robust support system of family and professionals increases the likelihood of successful treatment. Professionals provide the psychological and professional support needed to help the individual manage their feelings and negative thoughts, while family offers emotional support and a positive environment that fosters recovery.</li>
  <li><strong>Continuous Communication</strong>: Open and ongoing communication with family, friends, and professionals can be a decisive factor in the success of treatment. Individuals need to feel safe and connected, having the ability to express their feelings and thoughts without fear of judgment.</li>
</ul>

<h2>Obstacles the Individual May Face in the Recovery Journey</h2>

<p>While recovery is possible, numerous obstacles can hinder an individual’s ability to continue treatment. Among these challenges are:</p>

<ul>
  <li><strong>Relapses</strong>: The individual may experience a strong desire to return to substance use, especially during times of psychological or emotional stress. These relapses can be part of the recovery journey, and it is essential for the individual to be prepared to handle them appropriately.</li>
  <li><strong>Social Stigma</strong>: Society may view individuals with addiction negatively, complicating their reintegration into a new life after treatment.</li>
  <li><strong>Lack of Support</strong>: The absence of a strong support system from family and friends can lead the individual to feel isolated and lose motivation to continue treatment.</li>
</ul>

<h2>Is There a Permanent Cure?</h2>

<p>While there is no quick cure for addiction, individuals can achieve a state of sustained recovery. This means they can live a healthy and productive life without reliance on addictive substances but must always be prepared to face the challenges and pressures that may lead to relapse.</p>

<p>Permanent treatment requires a lifelong commitment, continued support from family and professionals, and learning how to manage life without dependence on drugs. The ultimate goal of treatment is for individuals to live a life filled with inner peace, happiness, and psychological stability.</p>

<h2>Conclusion</h2>

<p>Addiction is a complex disease that requires time and effort for complete recovery. There is no quick or magical solution, but with commitment, patience, and the right support, individuals can overcome this problem and begin anew. The recovery journey is not easy, but each step in the right direction brings the individual closer to healing. Patience and support are the foundations of any successful treatment, and we must always remember that everyone deserves a second chance for recovery and to live with dignity.</p>

    `,
    title:
      "Is There a Quick Cure for Addiction? Understanding the Treatment Journey and Path to Recovery",
    desc: "Addiction is a complex issue that affects both individuals and society as a whole. Family members often ask the pressing question: 'Is there a quick cure for addiction?' or 'When will my child recover?' Unfortunately, the truthful answer may be disappointing for some: addiction is not a disease that can be treated quickly. It is a long-term condition that requires time and effort for complete recovery. It is essential to understand that treatment is not merely about medication or a single therapy session; it is a lengthy process that necessitates multiple interventions and ongoing support from family and professionals.",
  },
];
