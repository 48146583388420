import React, { useRef, useEffect } from "react";
import videoSrc from "../../assets/images/WhatsApp Video 2024-11-13 at 15.07.39_f2095a9e.mp4"

const EliteWellbeingVideo = React.memo(() => {
  const videoRef = useRef(null);

  useEffect(() => {
    const videoElement = videoRef.current;

    const handlePlayPause = (entries) => {
      const [entry] = entries;
      if (entry.isIntersecting) {
        videoElement.play();
      } else {
        videoElement.pause();
      }
    };

    const observer = new IntersectionObserver(handlePlayPause, {
      threshold: 0.5, // Adjust threshold as needed
    });

    if (videoElement) {
      observer.observe(videoElement);
    }

    return () => {
      if (videoElement) {
        observer.unobserve(videoElement);
      }
    };
  }, []);

  return (
    <div className="rounded-xl overflow-hidden">
      <video
        ref={videoRef}
        muted
        autoPlay
        playsInline
        width={"100%"}
        controls
      >
        <source src={videoSrc} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
});

export default EliteWellbeingVideo;
