import React from "react";
import { comments, commentsData } from "../utils/constants";
import CommentSuccessStory from "../components/CommentSuccessStory";
import CommentsSection from "../components/shared/CommentsSection"

const SuccessStory = React.memo(() => {
  return (
    <div className="container flex flex-col gap-8">
      <CommentsSection data={commentsData} />
      <div className="flex flex-col gap-8">
        {comments.map((comment, index) => (
          <CommentSuccessStory key={index} comment={comment} reverse={index % 2 !== 0} />
        ))}
      </div>
    </div>
  );
});

export default SuccessStory;
