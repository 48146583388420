import { Button } from "antd";
import React, { useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import videoSrc from "../assets/images/saadV.mp4"

const Summary = React.memo(({
  reverse,
  title,
  Descriptions,
  btnURL,
  btnTitle,
  imageSrc,
  hideBtn,
  btnType,
  isVideo,
}) => {
  const saadVideoRef = useRef(null);

  useEffect(() => {
    const videoElement = saadVideoRef.current;

    const handlePlayPause = (entries) => {
      const [entry] = entries;
      if (entry.isIntersecting) {
        videoElement.play();
      } else {
        videoElement.pause();
      }
    };

    const observer = new IntersectionObserver(handlePlayPause, {
      threshold: 0.5,
    });

    if (videoElement) {
      observer.observe(videoElement);
    }

    return () => {
      if (videoElement) {
        observer.unobserve(videoElement);
      }
    };
  }, []);

  return (
    <div className="w-full">
      <div
        className={`w-full flex items-start gap-8 ${
          reverse ? "md:flex-row-reverse" : "md:flex-row"
        } flex-col-reverse`}
      >
        <div className="flex flex-col gap-4 w-full md:w-1/2 h-full justify-between">
          <span className="font-bold text-xl lg:text-4xl">{title}</span>
          <span>{Descriptions}</span>
          {!hideBtn && (
            <Link to={btnURL} className="md:w-fit w-full">
              <Button
                type={btnType ? btnType : "primary"}
                className="md:w-fit w-full"
              >
                {btnTitle}
              </Button>
            </Link>
          )}
        </div>
        <div className="rounded-lg overflow-hidden w-full md:w-1/2 md:max-h-96 md:h-96 h-fit">
          {!isVideo ? (
            <img
              src={imageSrc}
              alt="section_image"
              className="w-full h-full object-cover"
              loading="lazy"
            />
          ) : (
            <div className="rounded-xl overflow-hidden h-fit">
              <video
                ref={saadVideoRef}
                muted
                autoPlay
                playsInline
                width={"100%"}
                controls
              >
                <source src={videoSrc} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          )}
        </div>
      </div>
    </div>
  );
});

export default Summary;
