import React, { useEffect } from "react";
import Navbar from "./components/shared/Navbar";
import Home from "./pages/Home";
import EliteWellbeing from "./pages/EliteWellbeing";
import SuccessStory from "./pages/SuccessStory";
import ForYouth from "./pages/ForYouth";
import Footer from "./components/shared/Footer";
import WhatsappIcon from "./components/shared/WhatsappIcon";
import AboutSaad from "./pages/AboutSaad";
import { ConfigProvider } from "antd";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Cookies from "js-cookie";
import i18n from "./i18n";
import ItemDetails from "./pages/ItemDetails";
import ProgramDetails from "./pages/ProgramDetails";
import Blog from "./pages/Blog";
import SubProgramDetails from "./pages/SubProgramDetails";
import Blogs from "./pages/Blogs";
import Programs from "./pages/Programs";

function App() {
  useEffect(() => {
    // Set initial direction based on the language stored in cookies
    const initialLang = Cookies.get("i18next") || "en"; // Fallback to 'en' if no cookie is set
    window.document.dir = i18n.dir(initialLang);

    // Update direction when language changes
    const handleLanguageChange = (lng) => {
      Cookies.set("i18next", lng); // Update the language in the cookie
      window.document.dir = i18n.dir(lng); // Update the document direction
    };

    // Attach event listener for language change
    i18n.on("languageChanged", handleLanguageChange);

    // Clean up the event listener on component unmount
    return () => {
      i18n.off("languageChanged", handleLanguageChange);
    };
  }, []);

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#000000", // Primary color
          colorPrimaryHover: "#212121", // Lighter shade of primary on hover
          colorPrimaryShadow: "rgba(0, 0, 0, 0.2)", // Shadow color when button is active
        },
      }}
    >
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/elite_wellbeing" element={<EliteWellbeing />} />
          <Route path="/success_story" element={<SuccessStory />} />
          <Route path="/our-team" element={<ForYouth />} />
          <Route path="/our_story" element={<AboutSaad />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/details/:id" element={<ItemDetails />} />
          <Route path="/courses/:id" element={<ItemDetails />} />
          <Route path="/program" element={<Programs />} />
          <Route path="/program/:id" element={<ProgramDetails />} />
          <Route path="/blog/:id" element={<Blog />} />
          <Route
            path="/program-details/:programId/:id"
            element={<SubProgramDetails />}
          />
        </Routes>
        <WhatsappIcon />
        <Footer />
      </Router>
    </ConfigProvider>
  );
}

export default App;
