import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import { Button } from "antd";
import { coachingData, courses } from "../utils/constants";

const ItemDetails = React.memo(() => {
  const { id } = useParams();
  const location = useLocation();

  const [item, setItem] = useState(null);

  useEffect(() => {
    // Determine which dataset to use based on the path
    const dataset = location.pathname.includes("/courses")
      ? courses
      : coachingData;
    // Find the item in the selected dataset
    const itemData = dataset.find((item) => item.id === id);
    setItem(itemData);
    window.scrollTo(0, 0);
  }, [id, location.pathname]);

  return (
    <div className="container">
      <div className="w-full flex items-start gap-8 flex-col md:flex-row">
        <div className="rounded-xl overflow-hidden w-full md:w-1/2 h-screen">
          <img
            src={item?.img}
            alt="section_image"
            className="w-full h-full object-cover"
            loading="lazy"
          />
        </div>
        <div className="flex flex-col gap-4 w-full md:w-1/2 h-full justify-between">
          <h3 className="font-bold text-2xl lg:text-4xl text-highLight_color">
            {item?.name}
          </h3>
          <span className="text-textGray">{item?.title}</span>

          <span>{item?.description}</span>
          {item?.courseURL && (
            <a href={item?.courseURL} target="_blank" rel="noreferrer">
              <Button type="primary" className="w-full">
                Get Now
              </Button>
            </a>
          )}
        </div>
      </div>
    </div>
  );
});

export default ItemDetails;
