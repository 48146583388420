import React from "react";
import i18n from "../../i18n";
import Cookies from "js-cookie";
import { setIsArabic } from "../../features/SharedSlice";
import { useSelector, useDispatch } from "react-redux";
import { GlobalOutlined } from "@ant-design/icons";

const LangSwitch = React.memo(() => {
  const dispatch = useDispatch();

  const { isArabic } = useSelector((state) => ({
    isArabic: state.shared.isArabic,
  }));

  const changeLanguage = () => {
    dispatch(setIsArabic(!isArabic));
    const lang = isArabic ? "en" : "ar";
    i18n.changeLanguage(lang);
    Cookies.set("i18next", lang);
    window.document.dir = i18n.dir(lang);
  };

  return (
    <div>
      <div
        className="flex items-center gap-1 cursor-pointer"
        onClick={changeLanguage}
      >
        <span className="flex items-center justify-center"><GlobalOutlined /></span>
        <span className="mt-1">{isArabic ? "EN" : "AR"}</span>
      </div>
    </div>
  );
});

export default LangSwitch;
