import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { blogs } from "../utils/constants";

const Blog = React.memo(() => {
  const { id } = useParams();
  const [item, setItem] = useState(null);

  useEffect(() => {
    const itemData = blogs.find((item) => item.id === id);
    setItem(itemData);
  }, [id]);

  return (
    <div className="container flex flex-col gap-4 mt-8">
      <h3 className="font-bold text-2xl">{item?.title}</h3>
      <p className="text-textGray">{item?.desc}</p>
      <div className="content-container" dangerouslySetInnerHTML={{ __html: item?.points }}></div>
    </div>
  );
});

export default Blog;
