import React from "react";
import { programsFunc } from "../utils/constants";
import Summary from "../components/Summary.jsx";
import { useTranslation } from "react-i18next";

const Programs = React.memo(() => {

  const { t } = useTranslation();

  const programs = programsFunc(t)

  return (
    <div className="w-full mt-20">
      <div className="container flex flex-col gap-20">
        {programs.map((program, index) => (
          <div key={index}>
            <Summary
              title={<div>{program.name}</div>}
              Descriptions={<p>{program.desc}</p>}
              btnTitle={t('titles.read_more')}
              imageSrc={program.image}
              btnURL={`/program/${program.id}`}
              reverse={index % 2 === 0}
            />
          </div>
        ))}
      </div>
    </div>
  );
});

export default Programs;
